import styles from './header-filter.module.scss';
import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { formatText } from '../../utils/formatTextUtils';
import { ChevronDown, ChevronUp, Download } from 'react-feather';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { activePeriodState, endingDatesState } from '../../state/period';
import { hasValue } from '../../utils/recoilUtils';
import { ExportModal } from '../export-modal/export-modal';

/* eslint-disable-next-line */
export interface HeaderFilterProps {}

export function HeaderFilter() {
  const currentPeriod = useRecoilValueLoadable(endingDatesState);
  const period = useRecoilValueLoadable(activePeriodState);
  const setActivePeriodState = useSetRecoilState(activePeriodState);
  const [periodList, setPeriodList] = useState<string[]>([]);
  const [activePeriod, setActivePeriod] = useState('');

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (hasValue(currentPeriod) && currentPeriod) {
      setPeriodList([
        `1 week ending: ${currentPeriod.contents.latestWeek}`,
        `4 weeks ending: ${currentPeriod.contents.latestWeek}`,
      ]);

      if (period.contents === 'WEEK') {
        setActivePeriod(`1 week ending: ${currentPeriod.contents.latestWeek}`);
      } else {
        setActivePeriod(`4 weeks ending: ${currentPeriod.contents.latestWeek}`);
      }
    }
  }, [currentPeriod, period]);

  // TODO Add Spinner back
  // temporarily suppressing spinner since it is not dependent in a query

  return (
    <div className={styles['brand-filter']}>
      <NavDropdown
        data-testid="period-dropdown"
        className={`${styles['period']} ${styles['dropdown']} `}
        title={
          <div className={styles['filter-title']}>
            <div className={styles['format-text']}>{formatText(activePeriod)}</div>
            <ChevronDown size={16} color={'#474747'} />
          </div>
        }
      >
        <div className={styles['dropdown-menu']}>
          {[activePeriod, ...periodList.filter((b) => b !== activePeriod)].map((b, index) => (
            <NavDropdown.Item
              data-testid={'period' + index}
              key={index}
              onClick={() => {
                setActivePeriod(b);
                setActivePeriodState(b.startsWith('1') ? 'WEEK' : 'QUAD');
              }}
            >
              <div className={styles['format-text']}>{formatText(b)}</div>
              {index === 0 && <ChevronUp size={16} color={'#474747'} />}
            </NavDropdown.Item>
          ))}
        </div>
      </NavDropdown>

      <div className={styles['export']} onClick={handleShow} data-testid={'export-button'}>
        <Download size={15} color={'#000'} />
        <span>Export</span>
      </div>
      <ExportModal close={() => setShow(false)} show={show} pageName={'Digital Shelf Analytics'} />
    </div>
  );
}

export default HeaderFilter;
