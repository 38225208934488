import styles from './summary-card.module.scss';
import React from 'react';
import { Row } from 'react-bootstrap';
import { PercentChangeBadge } from '@spins-gcp/shared-components-react';
import { HighchartsWrapper } from '../highcharts-wrapper/highcharts-wrapper';
import Highcharts from 'highcharts';
import { determineColors } from '../../utils/chartUtils';
import { activePeriodState } from '../../state/period';
import { useRecoilValue } from 'recoil';
import { InlineLoadingSpinner } from '../inline-loading-spinner/inline-loading-spinner';
import { tooltipFormatter } from '../../utils/highchartsUtils';

/* eslint-disable-next-line */
export interface SummaryCardsProps {
  title: string;
  value: number | string | JSX.Element;
  percentChange: number;
  graphData: { x: number; y: number; week?: string; title?: string }[];
  hasBorder?: boolean;
  isNegativeMeasure?: boolean;
  isLoading?: boolean;
}

export function SummaryCard({
  title,
  value,
  percentChange,
  graphData,
  hasBorder = false,
  isNegativeMeasure = false,
  isLoading = false,
}: SummaryCardsProps) {
  const periodState = useRecoilValue(activePeriodState);
  const periodComparison = periodState === 'QUAD' ? '4 weeks' : 'week';
  const isPositive = percentChange > 0;
  const isZero = percentChange === 0 && !graphData.some((d) => d.y);

  const options: Highcharts.Options = {
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      gridLineColor: 'transparent',
      ...(isZero && { min: 0, max: 1 }), // Sets the line graph to the bottom if 0
    },
    xAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      tickColor: 'transparent',
      visible: false,
      crosshair: {
        dashStyle: 'Dash',
      },
      height: '100%',
    },
    chart: {
      backgroundColor: 'transparent',
      height: '100px',
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: title,
        type: 'areaspline',
        color: determineColors(isPositive, isNegativeMeasure, isZero),
        opacity: 0.75,
        fillOpacity: 0.25,
        data: graphData,
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      formatter: tooltipFormatter,
      shape: 'square',
      borderRadius: 8,
      borderColor: 'none',
      backgroundColor: 'transparent',
      style: {
        fontSize: '12px',
        fontWeight: 'Bold',
      },
      shadow: false,
      useHTML: true,
      className: styles['tooltip'],
      outside: true,
    },
  };

  return (
    <div className={`${styles['container']} ${hasBorder ? styles['with-border'] : ''}`}>
      <Row className={styles['title-row']}>
        <div>
          <div className={styles['title']}>{title}</div>
          <div className={styles['value']}>{isLoading ? <InlineLoadingSpinner /> : value}</div>
          <div className={styles['badge-container']}>
            {!isLoading && (
              <>
                <PercentChangeBadge
                  percentage={percentChange / 100}
                  decimals={0}
                  isNegativeMeasure={isNegativeMeasure}
                />
                <span className={styles['period-comparison']}>{`vs prior ${periodComparison}`}</span>
              </>
            )}
          </div>
        </div>
        <div className={styles[`small-chart-${periodState.toLowerCase()}`]}>
          {!isLoading && <HighchartsWrapper options={options}></HighchartsWrapper>}
        </div>
      </Row>
    </div>
  );
}

export default SummaryCard;
