import styles from './review-sales-trends.module.scss';
import React, { useMemo } from 'react';
import { HighchartsWrapper } from '../highcharts-wrapper/highcharts-wrapper';
import Highcharts from 'highcharts';
import { computeTicks, computeXCoordinate, computeYCoordinate } from '../../utils/chartUtils';
import { formatNumberToKMB } from '../../utils/numberUtils';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { activePeriodState } from '../../state/period';
import * as R from 'ramda';
import { salesSellerState } from '../../state/sales';
import { BrandSummaryWeeklyAggregateItem } from '@dsa-ui/dsa-shared';
import { hasValue } from '../../utils/recoilUtils';

export interface ReviewSalesTrendsProps {
  Ratings: { x: number; y: number }[];
}

export function ReviewSalesTrends({ Ratings }: ReviewSalesTrendsProps) {
  const title = useRecoilValue(activePeriodState) === 'QUAD' ? '4 Weeks' : '1 Week';
  const totalSellerSales = useRecoilValueLoadable(salesSellerState);
  const trend: BrandSummaryWeeklyAggregateItem[] = hasValue(totalSellerSales)
    ? R.sortWith<BrandSummaryWeeklyAggregateItem>([R.ascend(R.prop('week'))])(totalSellerSales.contents.data)
    : [];
  const periods = Ratings.map((r) => {
    return r.x;
  });
  const sales = trend.map((s) => {
    return s.totalSales + s.thirdPartyRevenue;
  });

  const ticks = useMemo(() => {
    return computeTicks(sales);
  }, [sales]);

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },

    tooltip: {
      borderWidth: 0,
      shared: true,
      shadow: false,
      useHTML: true,
      className: styles['tooltip'],

      positioner: /* istanbul ignore next */ function (labelWidth, __, { plotX, plotY }) {
        const x = computeXCoordinate(plotX, labelWidth, this.chart.chartWidth);

        const y = computeYCoordinate(plotY);
        return { x, y };
      },

      backgroundColor: 'transparent',
      formatter: /* istanbul ignore next */ function () {
        const content = this.points?.reduce(function (s, point) {
          return (
            s +
            `<div class='${styles['tooltip-label']}'>` +
            `<div><span class='${styles['tooltip-icon']}' style='background-color: ${point.color};'> &nbsp;&nbsp;&nbsp; </span>` +
            '<span>' +
            point.series.name +
            '</span></div>' +
            '<span>' +
            formatNumberToKMB(point.y as number) +
            '</span>' +
            '</div>'
          );
        }, '<b>' + new Date(parseInt(this.x as string)).toString().substring(4, 10) + '</b>');

        return '<div>' + content + '</div>';
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        color: '#474747',
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        fontFamily: "'Public Sans', sans-serif",
      },
      backgroundColor: '#F8F8F8',
      borderRadius: 4,
      margin: 20,
    },
    yAxis: [
      {
        tickPositions: ticks,
        opposite: true,
        title: {
          text: 'Sales',
          rotation: -90,
          style: {
            fontFamily: "'Public Sans', sans-serif",
          },
          margin: 30,
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          // format: '${value:,.0f}', // dollar sign, thousands separator, no decimal places
          formatter: function (yMe) {
            if (typeof yMe.value === 'string') return yMe.value;

            return formatNumberToKMB(yMe.value);
          },
          style: {
            fontFamily: "'Public Sans', sans-serif",
          },
        },
      },
      {
        tickPositions: [1, 2, 3, 4, 5],
        title: {
          text: 'Average Star Rating',
          style: {
            fontFamily: "'Public Sans', sans-serif",
          },
          margin: 30,
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '{value} Star', // dollar sign, thousands separator, no decimal places
          style: {
            fontFamily: "'Public Sans', sans-serif",
          },
        },
      },
    ],
    xAxis: {
      type: 'datetime',
      tickPositions: periods,
      title: {
        text: `${title} Ending`,
        y: 10,
        style: {
          fontFamily: "'Public Sans', sans-serif",
        },
        margin: 20,
      },
      tickColor: 'transparent',
      labels: {
        formatter: function (yMe) {
          return new Date(yMe.value).toString().substring(4, 10);
        },
        style: {
          fontFamily: "'Public Sans', sans-serif",
        },
      },
      crosshair: {
        dashStyle: 'Dash',
      },
    },
    time: {
      useUTC: true,
    },
    chart: {
      height: '260px',
      backgroundColor: 'transparent',
    },
    series: [
      {
        name: 'Ratings',
        type: 'line',
        color: '#E8B227',
        data: R.sortWith<{ x: number; y: number }>([R.ascend(R.prop('x'))])(Ratings),
        yAxis: 1,
      },
      {
        name: 'Total Sales',
        type: 'line',
        color: '#799ABB',
        data: trend.map((t: BrandSummaryWeeklyAggregateItem) => {
          return {
            x: new Date(t.week).getTime() + new Date().getTimezoneOffset() * (60 * 1000),
            y: t.totalSales + t.thirdPartyRevenue,
          };
        }),
        yAxis: 0,
      },
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: '#FFFFFF',
          lineWidth: 2,
        },
        allowPointSelect: true,
        // showCheckbox: true,
        events: {
          /* istanbul ignore next */
          checkboxClick() {
            if (this.visible) {
              this.hide();
            } else {
              this.show();
            }
          },
          /* istanbul ignore next */
          legendItemClick(e) {
            const chart = e.target.chart,
              index = e.target.index;
            chart.series[index].selected = this.selected = !this.visible;
          },
        },
      },
    },
    credits: { enabled: false },
  };

  return (
    <div className={styles['container']}>
      <HighchartsWrapper options={chartOptions} />
    </div>
  );
}

export default ReviewSalesTrends;
