import { Loadable, MutableSnapshot } from 'recoil';

export const RecoilState = {
  Loading: 'loading',
  HasValue: 'hasValue',
  HasError: 'hasError',
};

export type InitialRecoilState = (snap: MutableSnapshot) => void;
export type ClearSelectorCaches = () => void;
export type InitialSnapshotRetain = () => void;

export const isLoading = <T>(v: Loadable<T>) => v.state === 'loading';
export const hasValue = <T>(v: Loadable<T>) =>
  v.state === 'hasValue' && !!v.valueMaybe();
