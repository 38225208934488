import styles from './new-feature.module.scss';
import newFeature from '../../../assets/sidebar/new-feature.svg';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface NewFeatureProps {}

export function NewFeature() {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className={`${styles['container']} ${isVisible ? '' : 'd-none'}`} data-testid={'new-feature'}>
      <div className={'d-flex justify-content-between'}>
        <h1 className={styles['title']}>Category & Competitor Insights</h1>
        <FontAwesomeIcon
          color={'#B1B1B1'}
          icon={faX}
          onClick={() => {
            setIsVisible(false);
          }}
          data-testid={'new-feature-close-button'}
        />
      </div>
      <div className={styles['text']}>
        Get the full market view to see what is driving growth in the categories you compete in
      </div>
      <img src={newFeature} alt={'new feature icon'} className={styles['image']}></img>
      <Button
        className={styles['button']}
        variant="outline-light"
        href={'https://www.spins.com/amazon-solutions/'}
        target={'_blank'}
      >
        Learn more
      </Button>
    </div>
  );
}

export default NewFeature;
