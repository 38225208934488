import styles from './highlighted.module.scss';
import { useMemo } from 'react';

/* eslint-disable-next-line */
export interface HighlightedProps {
  children: string;
  textToHighlight: string;
}

export function Highlighted({ children, textToHighlight }: HighlightedProps) {
  const regexMatch = useMemo(() => {
    const SPECIAL_CHAR = /([.?*+^$[\]\\(){}|-])/g;
    const escapedSearch = textToHighlight.replace(SPECIAL_CHAR, '\\$1');
    return new RegExp(`(${escapedSearch})`, 'i');
  }, [textToHighlight]);
  return textToHighlight === '' ? (
    <span>{children}</span>
  ) : (
    <>
      {children
        .split(regexMatch)
        .filter((part) => part !== '')
        .map((part, i) =>
          regexMatch.test(part) ? (
            <span className={styles['highlighted']} key={part + i}>
              {part}
            </span>
          ) : (
            part
          )
        )}
    </>
  );
}

export default Highlighted;
