import styles from './full-page.module.scss';
import React, { PropsWithChildren } from 'react';
import { PageConfig } from '../../routes/pageRoutes';
import { authTokenUserState, userSellerIdState, isSideNavBarCollapsed } from '../../state/user';
import { Footer, Header, UserTitle } from '@spins-gcp/shared-components-react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { InlineLoadingSpinner } from '../../components/inline-loading-spinner/inline-loading-spinner';
import SideNavBar from '../../components/side-nav-bar/side-nav-bar';
import { Nav, NavDropdown } from 'react-bootstrap';
import { ChevronRight, Grid } from 'react-feather';
import PortalIcon from '../../../assets/portal-icon.svg';
import { environment } from '../../../environments/environment';
import { AuthInfo } from '@dsa-ui/dsa-shared';
import { hasValue, isLoading } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';

export interface FullPageProps extends PageConfig, PropsWithChildren {}

export function FullPage({ pageName, children }: FullPageProps) {
  const userAuthInfo = useRecoilValueLoadable<AuthInfo | undefined>(authTokenUserState);
  const hasAppAccess = useRecoilValueLoadable<string | undefined>(userSellerIdState);
  const isSideBarCollapsed = useRecoilValue(isSideNavBarCollapsed);

  const maybeUserInfo = userAuthInfo.valueMaybe();
  const maxWidth = isSideBarCollapsed ? 1600 : 1790;

  const userTitle =
    hasValue(userAuthInfo) && maybeUserInfo ? (
      <UserTitle
        firstName={maybeUserInfo.user.fname}
        lastName={maybeUserInfo.user.lname}
        companyName={maybeUserInfo.company.name}
        fontTheme={'light'}
      />
    ) : (
      <InlineLoadingSpinner />
    );

  if (isLoading(hasAppAccess)) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Header pageName={pageName} userTitle={userTitle} theme={'light'} maxWidth={maxWidth}>
        <Nav className={styles['nav-icons']}>
          <NavDropdown className={styles['app-switcher']} title={<Grid size={16} />}>
            <NavDropdown.Item href={environment.portalUri} target="_blank">
              <div className={styles['app-menu']}>
                <img src={PortalIcon} alt={'portal icon'} />
                <span>Portal</span>
                <ChevronRight size={16} />
              </div>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Header>
      <div className={`d-flex justify-content-center`}>
        {!!hasAppAccess.getValue() ? (
          <>
            <div className={`${styles['sidebar']} align-self-stretch`}>
              <SideNavBar />
            </div>
            <div className={`flex-grow-1 ${styles['content']}`}>
              <div id="mainContent" className={styles['container']}>
                {children}
              </div>
            </div>
          </>
        ) : (
          <span>No access</span>
        )}
      </div>

      <Footer maxWidth={maxWidth} />
    </>
  );
}
