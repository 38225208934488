import React, { useEffect, useRef, useState } from 'react';
import styles from './rating-table.module.scss';
import { Table } from 'react-bootstrap';
import TableFooter from './table-footer/table-footer';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { Highlighted } from '../highlighted/highlighted';
import { AsinItemDetailWithUnitSold } from '@dsa-ui/dsa-shared';
import { useTable } from '../../hooks/useTable';
import { filterArrayByKeys, scaleTableWidth } from '../../utils/helperUtils';
import { useSortColumns } from '../../hooks/useSortColumns';
import { Star } from 'react-feather';
import { ColumnType } from '../review-details-table/review-details-table';
import { formatNumber } from '@spins-gcp/shared-components-react';

export interface RatingByProduct extends Record<string, string | number | boolean> {
  asin: string;
  upc: string | number;
  title: string;
  brand: string;
  totalRatings: number;
  avgStarRating: number;
  negativeReviews: number;
  unitSales: number;
  analyzed: number;
  [key: string]: string | number;
}

export interface RatingTableProps {
  asins: AsinItemDetailWithUnitSold[];
  term: string;
  starFilter: { lowerBound?: number; upperBound?: number } | undefined;
}

export function RatingTable({ asins, term, starFilter }: RatingTableProps) {
  const filteredAsins = filterArrayByKeys(asins, term, ['asin', 'brand', 'productTitle']).filter((item) => {
    if (starFilter) {
      const { upperBound, lowerBound } = starFilter;
      if (!upperBound && lowerBound) return item.average >= lowerBound;
      if (!lowerBound && upperBound) return item.average <= upperBound;
      return upperBound && lowerBound && item.average >= lowerBound && item.average <= upperBound;
    }
    return true;
  });
  const { items, requestSort, CaretIcon } = useSortColumns<AsinItemDetailWithUnitSold>(
    filteredAsins,
    {
      recordKey: 'total',
      direction: 'descending',
    },
    ColumnType.STRING
  );
  const { page, setRowsPerPage, nextPage, prevPage, paginated, maxPage, setPage } =
    useTable<AsinItemDetailWithUnitSold>(items);

  const [tableWidth, setTableWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);
  useEffect(() => {
    if (tableRef) {
      setTableWidth(tableRef?.current?.clientWidth || 0);
    }
  }, [tableRef]);

  const headers = [
    {
      name: 'ASIN',
      width: 126,
      // field: 'asin',
    },
    // {
    //   name: 'UPC',
    //   width: 114,
    // },
    {
      name: 'Title',
      width: 217,
      field: 'productTitle',
      type: ColumnType.STRING,
    },
    {
      name: 'Brand',
      width: 108,
    },
    {
      name: '2 Year Ratings',
      width: 76,
      field: 'total',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Average Star Rating',
      width: 96,
      field: 'average',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Negative reviews',
      width: 84,
      field: 'negative',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Units Sold',
      width: 92,
      field: 'unitsSold',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Analyzed',
      width: 80,
    },
  ];

  const totalColumnWidth = headers.reduce((total, col) => total + col.width, 0);

  useEffect(() => {
    if (!!term || !!starFilter) {
      setPage(1);
    }
  }, [term, starFilter, setPage]);

  return (
    <div className={styles['rating-table']}>
      <Table className={`${styles['table']} table-bordered`} ref={tableRef}>
        <thead>
          <tr className={`${styles['header']}`}>
            {headers.map((h) => {
              return (
                <th
                  key={h.name}
                  className={styles['left']}
                  data-testid={`header-${h.name}`}
                  style={{ width: scaleTableWidth(h.width, tableWidth, totalColumnWidth) }}
                  onClick={() => h.field && requestSort(h.field, h.type)}
                >
                  <div>
                    <span>{h.name}</span> {h.field && <CaretIcon recordKey={h.field} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {paginated.length === 0 ? (
            <tr>
              <td colSpan={8}>No data found</td>
            </tr>
          ) : (
            paginated.map((item, index) => {
              return (
                <tr key={index} className={styles['nth-child']}>
                  <td className={styles['left']}>
                    <Link data-testid={`asin-link-${item.asin}`} to={`/reviews/${item.asin}`}>
                      <Highlighted textToHighlight={term}>{item.asin}</Highlighted>
                    </Link>
                  </td>
                  {/*<td className={styles['left']}>*/}
                  {/*  TBD*/}
                  {/*  /!*<Highlighted textToHighlight={search ? search : ''}>*!/*/}
                  {/*  /!*  {typeof item.upc == 'string' ? item.upc : item.upc.toString()}*!/*/}
                  {/*  /!*</Highlighted>*!/*/}
                  {/*</td>*/}
                  <td className={`${styles['left']} ${styles['scrolling-cell']}`} data-testid={`title-${index}`}>
                    <Highlighted textToHighlight={term}>{item.productTitle}</Highlighted>
                  </td>
                  <td className={styles['left']}>
                    <Highlighted textToHighlight={term}>{item.brand}</Highlighted>
                  </td>
                  <td className={styles['right']}>{item.total.toLocaleString('en-US')}</td>
                  <td>
                    {item.average.toFixed(1)}
                    <span className={styles['stars']}>
                      {/*<Star size={9} fill={'#F1A103'} color={'#F1A103'} />*/}
                      <div className={styles['stars']}>
                        <Rating
                          initialValue={item.average}
                          readonly={true}
                          size={14}
                          allowFraction={true}
                          fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={16} />}
                          emptyIcon={<Star color={'#B1B1B1'} fill={'#B1B1B1'} size={16} />}
                        />
                      </div>
                    </span>
                  </td>
                  <td className={styles['right']}>{item.negative.toLocaleString('en-US')}</td>
                  <td className={styles['right']}>
                    {formatNumber(item.unitsSold, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                  </td>
                  <td className={styles['right']}>TBD</td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <div className={styles['footer']}>
        <TableFooter
          maxPage={maxPage}
          nextPage={nextPage}
          previousPage={prevPage}
          currentPage={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

export default RatingTable;
