import styles from './highlight-item.module.scss';
import { AsinsReviewDetail } from '@dsa-ui/dsa-shared';
import { Rating } from 'react-simple-star-rating';
import { ChevronRight, Star } from 'react-feather';
import React from 'react';
import { Link } from 'react-router-dom';

export function HighlightItem({ asin, productImageUrls, rating }: AsinsReviewDetail) {
  return (
    <div className={styles['highlight-item-container']}>
      <div className={styles['section']}>
        <img src={productImageUrls?.[0]} alt="productImage" />
        <div className={styles['item-details']}>
          <div className={styles['rating-container']}>
            <span>{rating?.average.toFixed(1)}</span>
            <div className={styles['star-container']}>
              <Rating
                initialValue={rating?.average}
                readonly={true}
                size={14}
                allowFraction={true}
                fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={16} />}
                emptyIcon={<Star color={'#C7C7C7'} fill={'#C7C7C7'} size={16} />}
              />
            </div>
          </div>
          <Link to={`/reviews/${asin}`}>
            ASIN {asin} <ChevronRight size={16} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HighlightItem;
