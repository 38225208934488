import styles from './issues-mentioned.module.scss';
import { Col, Row } from 'react-bootstrap';
import ItemRow from './item-row/item-row';
import { IssueMentioned } from '@dsa-ui/dsa-shared';
import { issuesMentionedState } from '../../state/issuesMentioned';
import { useRecoilValueLoadable } from 'recoil';
import { isLoading } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import GhostText from '../../../assets/ghost.svg';
import Girl from '../../../assets/girl.svg';
import React from 'react';
import { Rating } from 'react-simple-star-rating';

/* eslint-disable-next-line */
export interface IssuesMentionedProps {
  setFilter: (value: string) => void;
  asin: string;
  term?: string;
}

export function IssuesMentioned({ setFilter, asin }: IssuesMentionedProps) {
  const issuesMentionedResult = useRecoilValueLoadable(
    issuesMentionedState({
      asin: asin,
    })
  );
  if (isLoading(issuesMentionedResult)) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles['container']}>
      {issuesMentionedResult?.contents && issuesMentionedResult?.contents.length !== 0 ? (
        <div>
          <Row className={styles['title-row']}>
            <Col className="p-0" xs={4}>
              Mentions
            </Col>
            <Col className="p-0" style={{ textAlign: 'center' }} xs={3}>
              Count
            </Col>
            <Col className="p-0" xs={5}>
              Average Ratings
            </Col>
          </Row>
          {issuesMentionedResult.contents?.map((issue: IssueMentioned, index: number) => (
            <ItemRow
              mention={issue.token}
              itemCount={issue.occurrences}
              starCount={issue.avgRatings}
              key={`issues-${index}`}
              onClick={setFilter}
            />
          ))}
        </div>
      ) : (
        <div className={styles['no-issues-container']}>
          <span className={styles['congrats']}>Congratulations!</span>
          <span className={styles['no-issues']}>This ASIN has no issues mentioned</span>

          <div className={styles['icons']}>
            <div className={styles['left']}>
              <img src={GhostText} alt={'Ghost Icon'} />
              <span>
                <Rating initialValue={4} readonly={true} size={10} />
              </span>
              <img src={GhostText} alt={'Ghost Icon'} />
              <span>
                <Rating initialValue={5} readonly={true} size={10} />
              </span>
            </div>
            <div className={styles['right']}>
              <img src={Girl} alt={'Girl'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IssuesMentioned;
