import RatingDistribution from '../rating-distribution';
import { useRecoilValueLoadable } from 'recoil';
import { ratingDistributionState } from '../../../state/ratingDistribution';
import { hasValue, isLoading } from '../../../utils/recoilUtils';
import { LoadingSpinner } from '../../loading-spinner/loading-spinner';
import React from 'react';
import { AverageData } from '@dsa-ui/dsa-shared';

export interface AsinRatingDistributionProps {
  brand: string;
  asin: string;
}

export function AsinRatingDistribution({ brand, asin }: AsinRatingDistributionProps) {
  const ratingDistributionResult = useRecoilValueLoadable(
    ratingDistributionState({
      brand: brand,
      asin: asin,
    })
  );
  const xDataRatingsDistribution: number[] = [];
  const yDataRatingsDistribution: AverageData = { positive: [], negative: [] };
  let totalNegative = 0;
  let totalPositive = 0;
  if (hasValue(ratingDistributionResult)) {
    for (const item of ratingDistributionResult.contents) {
      xDataRatingsDistribution.push(new Date(item.periodEnd).getTime());
      yDataRatingsDistribution.positive.push(item.positive);
      yDataRatingsDistribution.negative.push(item.negative);
      if (item.allNegative !== undefined && item.allPositive !== undefined) {
        totalNegative = item.allNegative;
        totalPositive = item.allPositive;
      }
    }
  }
  if (isLoading(ratingDistributionResult)) {
    return <LoadingSpinner />;
  }
  return (
    <RatingDistribution
      data={yDataRatingsDistribution}
      xData={xDataRatingsDistribution}
      totalNegative={totalNegative}
      totalPositive={totalPositive}
    />
  );
}
