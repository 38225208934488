import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

type WrapperProps = {
  children: JSX.Element;
};

export const ScrollToTop = ({ children }: WrapperProps) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
