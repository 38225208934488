import React, { useState } from 'react';
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap';
import styles from './reviewspage.module.scss';
import ReviewsSnapshot from '../../components/reviews-snapshot/reviews-snapshot';
import { ChevronDown, ChevronUp } from 'react-feather';
import ReviewSalesTrends from '../../components/review-sales-trends/review-sales-trends';
import { HighlightCard } from '@spins-gcp/shared-components-react';
import RatingDistribution from '../../components/rating-distribution/rating-distribution';
import RatingTable from '../../components/rating-table/rating-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { useRecoilValueLoadable } from 'recoil';
import { filteredAsinsState, mainRatingDistributionState } from '../../state/reviewsPage';
import { hasValue } from '../../utils/recoilUtils';
import { InlineLoadingSpinner } from '../../components/inline-loading-spinner/inline-loading-spinner';
import HeaderFilter from '../../components/header-filter/header-filter';
import { formatText } from '../../utils/formatTextUtils';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import ReviewHighlights from '../../components/review-highlights/review-highlights';
import { asinHighestLowestRatingState } from '../../state/asin';
import { ratingAndSalesTrends } from '../../state/ratingAndSalesTrends';

export function ReviewsPage() {
  const asins = useRecoilValueLoadable(filteredAsinsState);
  const lowestHighest = useRecoilValueLoadable(asinHighestLowestRatingState);
  const ratingAndSalesData = useRecoilValueLoadable(ratingAndSalesTrends);
  const [term, setTerm] = useState<string>('');

  const filterOptions = [
    {
      title: 'Star Rating: All',
      value: undefined,
    },
    {
      title: 'Positive Reviews: 4 to 5 stars',
      value: { lowerBound: 4 },
    },
    { title: 'Negative Reviews: 1 to 3 stars', value: { upperBound: 3 } },
    { title: '5 stars', value: { lowerBound: 4 } },
    { title: '4 stars', value: { lowerBound: 3, upperBound: 4 } },
    { title: '3 stars', value: { lowerBound: 2, upperBound: 3 } },
    { title: '2 stars', value: { lowerBound: 1, upperBound: 2 } },
    { title: '1 star', value: { upperBound: 1 } },
  ];
  const mainRatingDistributionResult = useRecoilValueLoadable(mainRatingDistributionState);

  const [filterTitle, setFilterTitle] = useState(filterOptions[0]);

  return (
    <Row className={'align-items-start'}>
      <Col className={'p-0'}>
        <Row>
          <div style={{ marginBottom: '20px', marginTop: '20px', height: '30px' }}>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className={styles['company-name']}>Reviews</h3>
              <HeaderFilter />
            </div>
          </div>
        </Row>
        <Row>
          <ReviewsSnapshot />
        </Row>
        <Row className="p-0" style={{ justifyContent: 'space-between' }}>
          <Col md={9} xs={9} s={9} className={'p-2'}>
            <HighlightCard
              title={'Ratings and Sales Trends'}
              externalStyles={{
                padding: '0',
                margin: '0',
                maxHeight: '326px',
              }}
            >
              {hasValue(ratingAndSalesData) && ratingAndSalesData.contents ? (
                <ReviewSalesTrends {...ratingAndSalesData.contents} />
              ) : (
                <LoadingSpinner />
              )}
            </HighlightCard>
          </Col>
          <Col md={3} xs={3} s={3} className={'p-2'}>
            <HighlightCard
              title={'Rating Distribution'}
              externalStyles={{ flex: '1 1 auto', margin: '0', padding: '0', height: '100%' }}
              childrenExternalStyles={{
                margin: '16px 16px 0 16px',
              }}
            >
              {hasValue(mainRatingDistributionResult) && mainRatingDistributionResult.contents ? (
                <RatingDistribution {...mainRatingDistributionResult.contents} />
              ) : (
                <span data-testid={'distribution-loading'}>
                  <LoadingSpinner />
                </span>
              )}
            </HighlightCard>
          </Col>
        </Row>
        <Row className={'p-0'}>
          <Col md={9} xs={9} s={9} className={'p-2'}>
            <HighlightCard
              title={'Ratings by Product'}
              additionalTitle={
                <InputGroup size="sm" className={styles['search']}>
                  <InputGroup.Text className={styles['search-icon']}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    className={styles['search-text']}
                    type="search"
                    placeholder="Search"
                    data-testid={'search'}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                  />
                </InputGroup>
              }
              header={
                <Dropdown className={styles['filter-container']} data-testid={'star'}>
                  <DropdownButton
                    variant="white"
                    title={
                      <div className={styles['filter-title']}>
                        <div className={styles['format-text']}>{formatText(filterTitle.title)}</div>
                        <ChevronDown size={16} color={'#474747'} />
                      </div>
                    }
                    className={styles['filter-button']}
                    data-testid="star-filter"
                    onSelect={(e) => {
                      const item = JSON.parse(e as string);
                      setFilterTitle(item);
                    }}
                  >
                    <div className={styles['dropdown-menu']}>
                      {[filterTitle, ...filterOptions.filter((options) => options.title !== filterTitle.title)].map(
                        (item, index) => {
                          return (
                            <Dropdown.Item
                              eventKey={JSON.stringify(item)}
                              className={styles['filter-options']}
                              key={index}
                              value={item.title}
                              data-testid={`star-filter-${index}`}
                            >
                              <div className={styles['format-text']}>{formatText(item.title)}</div>
                              {index === 0 && <ChevronUp size={16} color={'#474747'} />}
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </div>
                  </DropdownButton>
                </Dropdown>
              }
            >
              {hasValue(asins) && asins.contents.length > 0 ? (
                <RatingTable asins={asins.contents} term={term} starFilter={filterTitle.value}></RatingTable>
              ) : (
                <span data-testid={'table-loading'}>
                  <LoadingSpinner />
                </span>
              )}
            </HighlightCard>
          </Col>
          <Col md={3} s={3} xs={3} className={'p-2 ' + [styles['review-highlights']].join(' ')}>
            <HighlightCard
              title="Review Highlights"
              childrenExternalStyles={{
                padding: '16px',
              }}
              externalStyles={{ paddingBottom: '16px', margin: '0' }}
            >
              {hasValue(lowestHighest) ? (
                <ReviewHighlights
                  lowestItem={lowestHighest.contents.lowestItem}
                  highestItem={lowestHighest.contents.highestItem}
                ></ReviewHighlights>
              ) : (
                <InlineLoadingSpinner />
              )}
            </HighlightCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
