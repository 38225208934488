import { BeatLoader } from 'react-spinners';
import React from 'react';

/* eslint-disable-next-line */
export interface InlineLoadingSpinnerProps {
  size?: number;
}

export function InlineLoadingSpinner({ size = 10 }: InlineLoadingSpinnerProps) {
  return (
    <BeatLoader
      size={size}
      color="#264653"
      cssOverride={{ display: 'inline' }}
    />
  );
}
