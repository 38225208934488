import { ApolloClient, ApolloError, HttpLink, InMemoryCache, OperationVariables, QueryOptions } from '@apollo/client';
import { getAccessToken } from './jwtUtils';
import fetch from 'cross-fetch';
import { MutationOptions } from '@apollo/client/core/watchQueryOptions';
import { FetchResult } from '@apollo/client/link/core';
import { ApolloQueryResult, DefaultContext } from '@apollo/client/core/types';
import { logger } from '../../logging';
import { ExecutableDefinitionNode, OperationDefinitionNode } from 'graphql/language/ast';

export const createSdnClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: process.env['NX_APP_SDN_API_BASE_URL'],
      headers: { authorization: `Bearer ${getAccessToken()}` },
      fetch,
    }),
  });
};

export const sdnClient = createSdnClient();

export const mutateSdn = async <TData, TVariables = OperationVariables, TContext = DefaultContext>(
  mutation: MutationOptions<TData, TVariables, TContext>
): Promise<FetchResult<TData>> => {
  return sdnClient.mutate(mutation);
};

export const querySdn = async <T, TVariables = OperationVariables>(
  query: QueryOptions<TVariables, T>,
  retries = 0,
  maxRetries = 5
): Promise<ApolloQueryResult<T>> => {
  const queryName = query.query.definitions[0] as ExecutableDefinitionNode as OperationDefinitionNode;
  const name = queryName.name?.value;

  return sdnClient.query<T, TVariables>(query).catch((e: ApolloError) => {
    if (e.message.toLowerCase() === '404: not found' && retries < maxRetries) {
      const currentRetry = retries + 1;
      logger.warn(`Retrying ${name} ${currentRetry}`);
      return querySdn(query, currentRetry, maxRetries);
    } else {
      logger.warn(`Error from ${name}: ${e.message}`);
    }

    throw e;
  });
};
