import React from 'react';
import styles from './table-footer.module.scss';
import { ChevronLeft, ChevronRight } from 'react-feather';

export interface tableFooterProps {
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  maxPage: number;
  setPage: React.Dispatch<number>;
  setRowsPerPage: React.Dispatch<number>;
  currentRow?: number;
}

const TableFooter = ({
  currentPage,
  previousPage,
  maxPage,
  nextPage,
  setRowsPerPage,
  currentRow,
  setPage,
}: tableFooterProps) => {
  const rows = [10, 25, 50, 100];

  return (
    <div className={styles['footer']}>
      <span className={styles['footer-span']}>Items per page</span>

      <select
        onChange={(e) => {
          setRowsPerPage(parseInt(e.target.value));
          setPage(1);
        }}
        defaultValue={currentRow}
      >
        {rows.map((r, index) => (
          <option key={index} value={r}>
            {r}
          </option>
        ))}
      </select>

      <span className={styles['footer-span']}>
        {' '}
        {currentPage} of {maxPage}
      </span>

      <button
        onClick={previousPage}
        className={styles['footer-button']}
        data-testid="chevron-left"
        disabled={currentPage <= 1}
      >
        <ChevronLeft className={styles['chevron']} />
      </button>
      <button
        onClick={nextPage}
        className={styles['footer-button']}
        data-testid="chevron-right"
        disabled={currentPage >= maxPage}
      >
        <ChevronRight className={styles['chevron']} />
      </button>
    </div>
  );
};

export default TableFooter;
