export const computePercentChange = (previous?: number, current?: number) => {
  if (previous === undefined && current === undefined) {
    return undefined;
  }

  if (previous !== undefined) {
    if (current !== undefined) {
      if (previous === 0 && current === 0) return 0;
      else if (previous === 0 && current > 0) return 100;
      else if (previous === 0 && current < 0) return -100;
      return ((current - previous) / previous) * 100;
    }
  }

  if (current !== undefined) {
    if (current === 0) return 0;
  }

  return 100;
};
