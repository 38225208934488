import { Button, Modal } from 'react-bootstrap';
import styles from './export-modal.module.scss';
import { useState } from 'react';
import { snapshot } from '../../utils/screenshotUtils';
import { createPdf } from '../../utils/pdfUtils';
import { useSetRecoilState } from 'recoil';
import { reflowHighCharts } from '../../state/user';
import { X } from 'react-feather';

export interface ExportModal {
  show: boolean;
  close: () => void;
  pageName: string;
}

export function ExportModal({ show, close, pageName }: ExportModal) {
  const updateReflowHighcharts = useSetRecoilState(reflowHighCharts);
  const [exportOption, setExportOption] = useState<string | undefined>(undefined);

  const onExport = () => {
    if (exportOption === 'pdf') {
      createPdf(pageName, updateReflowHighcharts);
    }

    if (exportOption === 'png') {
      snapshot(pageName);
    }
    close();
  };

  const onCancel = () => {
    setExportOption(undefined);
    close();
  };
  return (
    <Modal show={show} backdrop={false} dialogClassName={styles['modal-container']} className={styles['modal-dialog']}>
      <Modal.Body className={styles['modal-body']}>
        <div className={styles['modal-header']}>
          <X size={20} className={styles['modal-close-icon']} onClick={onCancel} data-testid="fa-close-modal" />
          <div className={styles['title']}>Export</div>
        </div>
        <div className={styles['modal-content']}>
          <div className={styles['text-body']}>
            Creates an image file of the page you are currently viewing. Choose the file type from the options below.
          </div>
          <div className={styles['radio-group']}>
            <div className={styles['radio-option']}>
              <input
                type={'radio'}
                value={'PDF'}
                id={'pdf'}
                name={'export-option'}
                onClick={() => setExportOption('pdf')}
                data-testid={'pdf-button'}
                style={{ height: '16px', width: '16px' }}
              />
              <label>PDF</label>
            </div>
            <div className={styles['radio-option']}>
              <input
                type={'radio'}
                value={'PNG'}
                id={'png'}
                name={'export-option'}
                onClick={() => setExportOption('png')}
                data-testid={'png-button'}
                style={{ height: '16px', width: '16px' }}
              />
              <label>PNG</label>
            </div>
          </div>
        </div>
        <div className={styles['modal-footer']}>
          <div className={styles['line']}></div>
          <div className={styles['btn-group']}>
            <Button className={styles['cancel-btn']} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={!exportOption ? styles['disabled-btn'] : styles['confirm-btn']}
              onClick={onExport}
              disabled={!exportOption}
            >
              Confirm and export
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
