import html2canvas from 'html2canvas';
import { getTime } from './dateTimeUtils';

export function snapshot(pageName: string) {
  const element: HTMLElement | null = document.querySelector('#mainContent');
  if (!element) {
    return;
  }

  html2canvas(element).then((canvas) => {
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    a.download = `${pageName}-${getTime()}.png`;
    a.click();
  });
}
