import { atomFamily, selectorFamily } from 'recoil';
import { PeriodRatingDistribution } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { RatingDistributionQuery, RatingDistributionForAsinResult } from './queries';
import { logger } from '../../logging';
import { activePeriodState, endDateState } from './period';
import { companyIdState } from './company';

export type RatingDistributionQueryParams = {
  brand: string;
  asin: string;
};

export const ratingDistributionState = atomFamily({
  key: 'ratingDistribution',
  default: selectorFamily<PeriodRatingDistribution[] | undefined, RatingDistributionQueryParams>({
    key: 'RatingDistributionQuery',
    get:
      ({ brand, asin }) =>
      ({ get }) => {
        const period = get(activePeriodState);
        const endDate = get(endDateState);
        const companyId = get(companyIdState);
        return querySdn<RatingDistributionForAsinResult>({
          query: RatingDistributionQuery,
          variables: {
            brand,
            asin,
            period,
            endDate,
            companyId,
          },
        })
          .then((res) => {
            return res.data.dsaRatingDistributionForAsin;
          })
          .catch((e) => {
            logger.error(e);
            return undefined;
          });
      },
  }),
});
