import { atom, selector } from 'recoil';
import { BrandSummary } from '@dsa-ui/dsa-shared';
import { dsaBrandSalesSummary, DsaBrandSalesSummaryResult } from './queries';
import { querySdn } from '../utils/sdnClient';
import { logger } from '../../logging';
import { activePeriodState, endDateState } from './period';
import { userBrandState } from './user';
import { companyIdState } from './company';

export const salesSellerState = atom({
  key: 'sellerSales',
  default: selector<BrandSummary | undefined>({
    key: 'SellerSales',
    get: ({ get }) => {
      const brand = get(userBrandState);
      const period = get(activePeriodState);
      const endDate = get(endDateState);
      const companyId = get(companyIdState);
      if (!brand) {
        logger.info('Seller id is undefined');
        return undefined;
      }
      return querySdn<DsaBrandSalesSummaryResult>({
        query: dsaBrandSalesSummary,
        variables: { brand, endDate, period, companyId },
      })
        .then((res) => res.data.dsaBrandSalesSummary)
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});
