import styles from '../review-details-table.module.scss';
import { Rating } from 'react-simple-star-rating';
import { ExternalLink, Info, Star } from 'react-feather';
import { Form, OverlayTrigger } from 'react-bootstrap';
import Highlighted from '../../highlighted/highlighted';
import GrowingTextarea from '../../growing-textarea/growing-textarea';
import React, { useEffect, useState } from 'react';
import { NoteRequest, ReviewWithNotes } from '@dsa-ui/dsa-shared';
import { useDebounce } from '../../../hooks/useDebounce';
import { mutateSdn } from '../../../utils/sdnClient';
import { UpsertNoteMutation, UpsertNoteResult } from '../../../state/queries';
import { logger } from '../../../../logging';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { reviewNoteState } from '../../../state/reviewNote';
import { DateTime } from 'luxon';
import { companyIdState } from '../../../state/company';
import { hasValue } from '../../../utils/recoilUtils';

export interface ReviewDetailsRowProps {
  item: ReviewWithNotes;
  term: string;
}
export function ReviewDetailsRow({ item, term }: ReviewDetailsRowProps) {
  const stars = item.stars ?? 0;
  const companyId = useRecoilValueLoadable(companyIdState);
  const setReviewNote = useSetRecoilState(reviewNoteState({ asin: item.asin, reviewId: item.review_id }));

  const [note, setNote] = useState<NoteRequest | undefined>(undefined);
  const debouncedNote = useDebounce(note);

  useEffect(() => {
    if (debouncedNote && hasValue(companyId)) {
      mutateSdn<UpsertNoteResult>({
        mutation: UpsertNoteMutation,
        variables: {
          data: debouncedNote,
          companyId: companyId.contents,
        },
      })
        .then(() => {
          logger.info('Successfully added/updated a note');
          setReviewNote({ note: debouncedNote.note as string, isAnalyzed: !!debouncedNote.isAnalyzed });
        })
        .catch((e) => logger.error('Unable to process note due to', e));
    }
  }, [debouncedNote, setReviewNote, companyId]);
  const MediaLink = ({ item }: { item: ReviewWithNotes }) => {
    if (item.image_links && item.video_links) {
      return (
        <span className={styles['media-link-active']} data-testid={`media-image-video`}>
          <a href={parseReviewUrl(item)} target="_blank" rel="noreferrer">
            Image
          </a>
          +
          <a href={parseReviewUrl(item)} target="_blank" rel="noreferrer">
            Video
          </a>
          <ExternalLink color={'#0C84D6'} size={12} />
        </span>
      );
    }
    if (item.image_links) {
      return (
        <span className={styles['media-link-active']} data-testid={`media-image`}>
          <a href={parseReviewUrl(item)} target="_blank" rel="noreferrer">
            <span>Image</span>
            <ExternalLink color={'#0C84D6'} size={12} />
          </a>
        </span>
      );
    }
    if (item.video_links) {
      return (
        <span className={styles['media-link-active']} data-testid={`media-video`}>
          <a href={parseReviewUrl(item)} target="_blank" rel="noreferrer">
            <span>Video</span>
            <ExternalLink color={'#0C84D6'} size={12} />
          </a>
        </span>
      );
    }
    return (
      <span className={styles['media-link']} data-testid={`media-none`}>
        <a target="_blank" rel="noreferrer">
          <span>n/a</span>
        </a>
        <ExternalLink color={'#808080'} size={12} />
      </span>
    );
  };
  const parseReviewUrl = (item: ReviewWithNotes): string | undefined => {
    if (!item.review_url) {
      return undefined;
    }
    return item.review_url;
  };

  return (
    <tr className={styles['table-row']}>
      <td className={`${styles['left']}`}>{new Date(parseInt(item.review_date)).toLocaleDateString()}</td>
      <td data-testid="stars" data-stars={stars}>
        {stars > 3 ? (
          <div className={styles['stars']}>
            <Rating
              initialValue={stars}
              readonly={true}
              size={14}
              allowFraction={true}
              fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={16} />}
              emptyIcon={<Star color={'#B1B1B1'} fill={'#B1B1B1'} size={16} />}
            />
          </div>
        ) : (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 250, hide: 400 }}
            defaultShow={false}
            overlay={<div className={styles['tooltip']}>Check Amazon's low rating refund options.</div>}
            placement={'top'}
            flip={false}
            onHide={undefined}
            onToggle={undefined}
            popperConfig={undefined}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Info size={11} color={'#808080'}></Info>
              <div className={styles['stars']}>
                <Rating
                  initialValue={stars}
                  readonly={true}
                  size={14}
                  allowFraction={true}
                  fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={16} />}
                  emptyIcon={<Star color={'#B1B1B1'} fill={'#B1B1B1'} size={16} />}
                />
              </div>
            </div>
          </OverlayTrigger>
        )}
      </td>
      <td className={`${styles['left']}`}>
        {item.found_helpful}
        {item.found_helpful === 1 ? ' person' : ' people'}
      </td>
      <td className={styles['left']}>
        {item.review_url ? (
          <a className={styles['media-link-active']} href={parseReviewUrl(item)} target="_blank" rel="noreferrer">
            <Highlighted textToHighlight={term}>{item.review_title as string}</Highlighted>
          </a>
        ) : (
          <span>
            <Highlighted textToHighlight={term}>{item.review_title as string}</Highlighted>
          </span>
        )}
      </td>
      <td className={styles['scrolling-cell']}>
        <Highlighted textToHighlight={term}>{item.review as string}</Highlighted>
      </td>
      <td className={`${styles['left']}`} data-testid={`image-video`}>
        <MediaLink item={item} />
      </td>
      <td>
        <Form.Check
          type="checkbox"
          data-testid="checkbox"
          defaultChecked={item?.note?.is_analyzed ?? false}
          onChange={(e) => {
            setNote({
              ...note,
              reviewId: item.review_id,
              isAnalyzed: e.target.checked,
            });
          }}
        />
      </td>
      <td>
        <GrowingTextarea
          value={item?.note?.note ?? ''}
          noteId={item?.note?.id ?? ''}
          updatedAt={
            item?.note?.updated_at
              ? DateTime.fromJSDate(new Date(parseInt(item?.note?.updated_at as unknown as string))).toMillis()
              : undefined
          }
          placeholder="Add a note"
          setNote={(value) => {
            setNote({
              ...note,
              reviewId: item.review_id,
              note: value.toString(),
            });
          }}
        />
      </td>
    </tr>
  );
}
