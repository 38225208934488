import Highcharts, { TooltipFormatterCallbackFunction, AxisLabelsFormatterCallbackFunction } from 'highcharts';
import { formatNumberToKMB } from './numberUtils';

type CustomPoint = {
  week: string;
  y: number;
  title: string;
};
export const tooltipFormatter: TooltipFormatterCallbackFunction = function () {
  const point = this.point as unknown as CustomPoint;
  return `
<span>${Highcharts.dateFormat('%b %d, %Y', new Date(point.week).getTime())}</span>
<br/>
<span style="color: #808080"> ${point.title}  </span>
<span>
<b>${formatNumberToKMB(point.y)}</b>
</span>`;
};

export const labelFormatter: AxisLabelsFormatterCallbackFunction = function (ctx) {
  if (ctx.isFirst) {
    return `<span style="text-align: left">${Highcharts.dateFormat('%b %d %Y', parseInt(ctx.value.toString()))}</span>`;
  } else {
    return `<span style="text-align: left">${Highcharts.dateFormat('%b %d', parseInt(ctx.value.toString()))}</span>`;
  }
};
