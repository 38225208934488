import styles from './review-highlights.module.scss';
import { AsinsReviewDetail } from '@dsa-ui/dsa-shared';
import HighlightItem from './highlight-item/highlight-item';

export interface ReviewHighlightsProps {
  highestItem: AsinsReviewDetail;
  lowestItem: AsinsReviewDetail;
}
export function ReviewHighlights({ highestItem, lowestItem }: ReviewHighlightsProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['section']}>
        <div className={styles['review-highlight']}>Highest Rated Product</div>
        <div>
          <HighlightItem {...highestItem} />
        </div>
        <div className={styles['review-highlight']}>Lowest Rated Product</div>
        <div>
          <HighlightItem {...lowestItem} />
        </div>
      </div>
    </div>
  );
}

export default ReviewHighlights;
