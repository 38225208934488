import { atom, selector, selectorFamily } from 'recoil';
import { asinsState } from './reviewsPage';
import { AsinItem, HighestLowest } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { AsinsReviewDetailResult, GetReviewHighlightsQuery } from './queries';
import { logger } from '../../logging';
import { userBrandState } from './user';
import { endDateState } from './period';
import { companyIdState } from './company';

export const asinProductDetailsState = selectorFamily<AsinItem | undefined, { asin: string }>({
  key: 'asinProductDetailsState',
  get:
    ({ asin }) =>
    ({ get }) => {
      const asins = get(asinsState);

      return asins?.find((r) => r.asin === asin) ?? undefined;
    },
});

export const asinHighestLowestRatingState = atom({
  key: 'highestLowestRatingState',
  default: selector<HighestLowest | undefined>({
    key: 'GetHighestLowestRating',
    get: ({ get }) => {
      const brand = get(userBrandState);
      const endDate = get(endDateState);
      const companyId = get(companyIdState);
      if (!brand) {
        logger.info('Brand is undefined');
        return undefined;
      }

      return querySdn<AsinsReviewDetailResult>({
        query: GetReviewHighlightsQuery,
        variables: {
          brand,
          endDate,
          companyId,
        },
      })
        .then((r) => {
          return r.data.dsaGetReviewHighlights;
        })
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});
