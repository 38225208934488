export const formatSales = (value: number) => {
  const result = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 1,
  }).format(value);

  const temp = result.split('.');

  return temp[1] === '0' ? temp[0] : result;
};
