import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import { ApolloProvider } from '@apollo/client';
import { sdnClient } from './app/utils/sdnClient';
import { RecoilRoot } from 'recoil';
import { logger } from './logging';
import { environment } from './environments/environment';

logger.info('>> APP VERSION: ', environment.appVersion);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ApolloProvider client={sdnClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ApolloProvider>
  </StrictMode>
);
