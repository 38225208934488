import { XYDataPoint } from '@dsa-ui/dsa-shared';
import { computePercentChange } from '../../utils/mathUtils';

export type UnitsTransformResult = {
  currentUnitSold: number;
  unitPercentChange?: number;
  graphData: XYDataPoint[];
};
export function unitsDataTransform(
  unitsSold: { unitsSold: number; week?: string; endDate?: string }[] | undefined
): UnitsTransformResult {
  let currentUnitSold = 0,
    previousUnitSold = 0,
    unitPercentChange: number | undefined = 0;
  let graphData = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 },
    { x: 10, y: 0 },
    { x: 11, y: 0 },
    { x: 12, y: 0 },
  ];

  const unitsData: { unitsSold: number; week?: string; endDate?: string }[] | undefined = unitsSold;
  if (unitsData && unitsData.length > 0) {
    currentUnitSold = unitsData[0].unitsSold;
    previousUnitSold = unitsData[1]?.unitsSold ?? 0;
    unitPercentChange = computePercentChange(previousUnitSold, currentUnitSold);
    graphData = [...unitsData].reverse().map(({ unitsSold, week }, index) => {
      return { x: index, y: unitsSold, week, title: 'Units' };
    });
  }

  return {
    currentUnitSold,
    unitPercentChange,
    graphData,
  };
}
