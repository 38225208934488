import { atom, selector } from 'recoil';
import { activePeriodState } from './period';
import { companyIdState } from './company';
import { querySdn } from '../utils/sdnClient';
import { RatingAndSalesTrendDataQuery, RatingAndSalesTrendDataResult } from './queries';
import { ReviewSalesTrendsProps } from '../components/review-sales-trends/review-sales-trends';
import { userBrandState, userSellerIdState } from './user';
import { logger } from '../../logging';

export const ratingAndSalesTrends = atom({
  key: 'RatingAndSalesTrends',
  default: selector<ReviewSalesTrendsProps | undefined>({
    key: 'RatingAndSalesTrendsQuery',
    get: ({ get }) => {
      const period = get(activePeriodState);
      const brand = get(userBrandState);
      const sellerId = get(userSellerIdState);
      const companyId = get(companyIdState);
      return querySdn<RatingAndSalesTrendDataResult>({
        query: RatingAndSalesTrendDataQuery,
        variables: {
          brand,
          period,
          sellerId,
          companyId,
        },
      })
        .then((r) => {
          const response = r.data.dsaRatingAndSalesTrendData;
          return {
            Ratings: response.ratings.map((item) => ({
              x: new Date(item.date).getTime() + new Date().getTimezoneOffset() * (60 * 1000),
              y: item.value,
            })),
          };
        })
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});
