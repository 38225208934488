import styles from './reviews.module.scss';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SummaryCard from '../summary-card/summary-card';
import { Rating } from 'react-simple-star-rating';
import ReviewSummary from '../review-summary/review-summary';
import { formatNumber } from '@spins-gcp/shared-components-react';
import { Star } from 'react-feather';

/* eslint-disable-next-line */
export interface ReviewsProps {
  reviewsCount: number;
  averageRating: number;
  averageRatingPercentChanged?: number;
  reviewsCountPercentChanged?: number;
  reviewRatingSummaryData?: {
    star1: number;
    star2: number;
    star3: number;
    star4: number;
    star5: number;
  };
  avgRatingHistoricalData?: { x: number; y: number; week?: string; title?: string }[];
  ratingsHistoricalData?: { x: number; y: number; week?: string; title?: string }[];
}

export function Reviews({
  reviewsCount,
  averageRating,
  averageRatingPercentChanged = 10,
  reviewsCountPercentChanged = 10,
  reviewRatingSummaryData = {
    star1: 2,
    star2: 7,
    star3: 11,
    star4: 24,
    star5: 56,
  },
  ratingsHistoricalData,
  avgRatingHistoricalData,
}: ReviewsProps) {
  const reviewSummaryGraphData = [
    {
      label: '5',
      percentage: Math.round(reviewRatingSummaryData.star5),
      color: '#E8B227',
    },
    {
      label: '4',
      percentage: Math.round(reviewRatingSummaryData.star4),
      color: '#E8B227',
    },
    {
      label: '3',
      percentage: Math.round(reviewRatingSummaryData.star3),
      color: '#E8B227',
    },
    {
      label: '2',
      percentage: Math.round(reviewRatingSummaryData.star2),
      color: '#E8B227',
    },
    {
      label: '1',
      percentage: Math.round(reviewRatingSummaryData.star1),
      color: '#E8B227',
    },
  ];

  const rating =
    averageRating % 1
      ? formatNumber(averageRating, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
      : formatNumber(averageRating, { maximumFractionDigits: 0, minimumFractionDigits: 0 });

  const avgRating = avgRatingHistoricalData?.map((obj) => ({
    ...obj,
    title: 'Average',
  }));
  const ratingHistorical = ratingsHistoricalData?.map((obj) => ({
    ...obj,
    title: 'Ratings',
  }));
  return (
    <Row>
      <Col className={'p-2'}>
        <SummaryCard
          hasBorder={true}
          title="Ratings"
          value={formatNumber(reviewsCount)}
          percentChange={reviewsCountPercentChanged}
          isLoading={!ratingsHistoricalData}
          graphData={ratingHistorical || []}
        />
      </Col>
      <Col className={'p-2'}>
        <SummaryCard
          hasBorder={true}
          title="Average Rating"
          value={
            <>
              {rating}{' '}
              <div className={styles['star-container']}>
                <Rating
                  initialValue={averageRating}
                  readonly={true}
                  size={18}
                  allowFraction={true}
                  fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={18} />}
                  emptyIcon={<Star color={'#C7C7C7'} fill={'#C7C7C7'} size={18} />}
                />
              </div>
            </>
          }
          percentChange={averageRatingPercentChanged}
          graphData={avgRating || []}
          isLoading={!avgRatingHistoricalData}
        />
      </Col>
      <Col className={'p-2'}>
        <div className={styles['review-card']}>
          <ReviewSummary data={reviewSummaryGraphData} />
        </div>
      </Col>
    </Row>
  );
}

export default Reviews;
