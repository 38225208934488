import { atom, selector } from 'recoil';
import { getEndDates, WeekEnding } from '@dsa-ui/dsa-shared';

export const endingDatesState = atom<WeekEnding>({
  key: 'CurrentPeriodState',
  default: selector({
    key: 'CurrentPeriodStateSelector',
    get: () => {
      return getEndDates();
    },
  }),
});

export const activePeriodState = atom({
  key: 'ActivePeriodState',
  default: selector<'WEEK' | 'QUAD'>({
    key: 'ActivePeriodStateKey',
    get: () => 'WEEK',
  }),
});

export const endDateState = atom({
  key: 'EndDateState',
  default: selector({
    key: 'EndDateStateKey',
    get: ({ get }) => {
      return get(endingDatesState)?.latestWeek;
    },
  }),
});
