import styles from './growing-textarea.module.scss';
import { Form } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

export interface GrowingTextareaProps {
  value?: string;
  placeholder?: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  noteId: string;
  updatedAt: number | undefined;
}

export function countLines(textarea: HTMLTextAreaElement, uid: string) {
  const cs = window.getComputedStyle(textarea);
  const pl = parseInt(cs.paddingLeft);
  const pr = parseInt(cs.paddingRight);
  let lh = parseInt(cs.lineHeight);
  const _buffer = document.querySelector(`#growing-textarea-buffer-${uid}`) as HTMLTextAreaElement;
  // console.log(_buffer);

  // [cs.lineHeight] may return 'normal', which means line height = font size.
  if (isNaN(lh)) lh = parseInt(cs.fontSize);

  // Copy content width.
  _buffer.style.width = textarea.clientWidth - pl - pr + 'px';

  // Copy text properties.
  _buffer.style.font = cs.font;
  _buffer.style.letterSpacing = cs.letterSpacing;
  _buffer.style.whiteSpace = cs.whiteSpace;
  _buffer.style.wordBreak = cs.wordBreak;
  _buffer.style.wordSpacing = cs.wordSpacing;
  _buffer.style.wordWrap = cs.wordWrap;

  // Copy value.
  _buffer.value = textarea.value;

  let result = Math.floor(_buffer.scrollHeight / lh);
  if (result === 0) result = 1;
  return result;
}

export function GrowingTextarea({ value, placeholder, setNote, noteId, updatedAt }: GrowingTextareaProps) {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const maxChars = 256;
  const [text, setText] = useState<string>(value ?? '');
  const [updatedAtText, setUpdatedAtText] = useState<string>('');
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    textRef && textRef.current && (textRef.current.style.height = countLines(textRef.current, noteId) * 18 + 20 + 'px');
    if (updatedAt) setUpdatedAtText(DateTime.fromMillis(updatedAt).toFormat('M/d/yy'));
  }, [text, noteId, updatedAt]);

  return (
    <div>
      <Form.Control
        className={styles['growing-textarea']}
        as="textarea"
        ref={textRef}
        maxLength={maxChars}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          setNote(e.target.value);
          setIsChanged(true);
        }}
        onFocus={() => setIsChanged(true)}
        onBlur={() => setIsChanged(false)}
        placeholder={placeholder}
        data-testid={'textarea'}
      />
      <textarea className={styles['textarea-size-buffer']} id={`growing-textarea-buffer-${noteId}`}></textarea>
      {isChanged && text.length > 0 ? (
        <div data-testid="char-count" className={styles['char-count']}>
          {maxChars - text.length} characters left
        </div>
      ) : (
        <div className={styles['char-count']}>&nbsp;</div>
      )}
      {text.length > 0 ? <div className={styles['updated-at']}>{updatedAtText}</div> : <></>}
    </div>
  );
}

export default GrowingTextarea;
