type JwtAuth = {
  auth: {
    company: {
      id: string;
    };
  };
};

export const getUserEmailFromJwt = (jwt: Record<string, unknown> | undefined): string | undefined => {
  if (!jwt) {
    return undefined;
  }
  return jwt['https://aot/email'] as string;
};

export const getCompanyIdFromJwt = (jwt: Record<string, unknown> | undefined): string | undefined => {
  if (!jwt) {
    return undefined;
  }
  return (jwt['https://aot/userSecurityFields'] as JwtAuth).auth.company.id as string;
};
