import styles from './asin-details-card.module.scss';
import { Tab, Tabs } from 'react-bootstrap';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { asinProductDetailsState } from '../../state/asin';
import { hasValue } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { ExternalLink } from 'react-feather';

/* eslint-disable-next-line */
export interface AsinDetailsCardProps {
  asin: string;
}

export function AsinDetailsCard({ asin }: AsinDetailsCardProps) {
  const asinDetails = useRecoilValueLoadable(asinProductDetailsState({ asin }));

  return (
    <div className={styles['container']}>
      <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="details" title="ASIN Details">
          {hasValue(asinDetails) ? (
            <div className={styles['content-container']}>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={styles['details-title']}>{asinDetails.contents.productTitle}</div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                    <img
                      src={asinDetails.contents.imageUrls[0]}
                      alt={asinDetails.contents.productTitle}
                      className={styles['image']}
                      data-testid="productImage"
                    />
                    <div className={styles['product-details']}>
                      <div>View content customers are responding to on Amazon</div>
                      <a
                        href={asinDetails.contents.productUrl}
                        rel="noreferrer"
                        target="_blank"
                        className={styles['amazon-link']}
                      >
                        Go to Amazon &nbsp;
                        <ExternalLink size={16} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </Tab>
        <Tab
          eventKey="competitorRatings"
          title={<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Competitor Product Ratings</div>}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '270px',
            }}
          >
            <span className={styles['coming-soon']}>Coming Soon!</span>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AsinDetailsCard;
