import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useRef } from 'react';
import { reflowHighCharts } from '../../state/user';
import { useRecoilValue } from 'recoil';
import * as R from 'ramda';

export interface HighchartsWrapperProps {
  options: Highcharts.Options;
}

export function HighchartsWrapper({ options }: HighchartsWrapperProps) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const highChartsReflow = useRecoilValue(reflowHighCharts);

  const memoizedOptions = useMemo(
    () =>
      R.clone({
        accessibility: {
          enabled: false,
        },
        ...options,
      }),
    [options]
  );

  useEffect(() => {
    if (highChartsReflow === 0) {
      return;
    }

    chartComponentRef.current?.chart.reflow();
  }, [highChartsReflow, chartComponentRef]);

  return <HighchartsReact highcharts={Highcharts} options={memoizedOptions} ref={chartComponentRef} immutable={true} />;
}
