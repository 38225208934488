import { useState, useMemo } from 'react';

export function useTable<T>(data: T[]) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const maxPage = Math.ceil(data.length / rowsPerPage);

  const paginated = useMemo(() => data.slice(rowsPerPage * (page - 1), rowsPerPage * page), [data, rowsPerPage, page]);
  const canNext = page < maxPage;
  const canPrev = page > 1;

  const nextPage = () => {
    canNext && setPage(page + 1);
  };
  const prevPage = () => {
    canPrev && setPage(page - 1);
  };

  return {
    page,
    setRowsPerPage,
    nextPage,
    prevPage,
    canPrev,
    canNext,
    paginated,
    maxPage,
    setPage,
  };
}
