import arrowUp from '../../assets/arrows/arrow-up.svg';
import arrowDown from '../../assets/arrows/arrow-down.svg';
export const TOOLTIP_HORIZONTAL_OFFSET = 120;
export const TOOLTIP_HORIZONTAL_OFFSET_REVERSE = 90;
export const CHART_XAXIS_LABEL_BOTTOM = 70;

export const computeXCoordinate = (
  plotX: number,
  labelWidth: number,
  chartWidth: number,
  offset: number = TOOLTIP_HORIZONTAL_OFFSET,
  reverseOffset: number = TOOLTIP_HORIZONTAL_OFFSET_REVERSE
) => {
  return plotX + labelWidth + offset >= chartWidth ? plotX - labelWidth + reverseOffset : plotX + offset;
};

export const computeXSummaryCard = (plotX: number, labelWidth: number, chartWidth: number) => {
  if (plotX + labelWidth <= chartWidth + 10) {
    return plotX + 5;
  }
  return plotX - labelWidth + 5;
};

export const computeYCoordinate = (plotY: number, labelBottom: number = CHART_XAXIS_LABEL_BOTTOM) => {
  return plotY > labelBottom ? labelBottom : plotY;
};

const IS_POSITIVE_TRUE = true;
const IS_POSITIVE_FALSE = false;
const INVERT_COLORS_TRUE = true;
const INVERT_COLORS_FALSE = false;
const colorsMap = {
  [+IS_POSITIVE_TRUE]: {
    [+INVERT_COLORS_TRUE]: '#EA0505',
    [+INVERT_COLORS_FALSE]: '#00A656',
  },
  [+IS_POSITIVE_FALSE]: {
    [+INVERT_COLORS_TRUE]: '#00A656',
    [+INVERT_COLORS_FALSE]: '#EA0505',
  },
};

export const determineColors = (isPositive: boolean, invertColors: boolean, isZero?: boolean) => {
  if (isZero) {
    return '#808080';
  }
  return colorsMap[+isPositive][+invertColors];
};

export const computeTicks = (sales: number[]) => {
  const lowest = Math.min(...sales);
  const highest = Math.max(...sales);
  const range = highest - lowest;

  let ticks: number[] = [0, 0.25, 0.5, 0.75, 1].map((percentage) => {
    return lowest + percentage * range;
  });

  ticks = ticks.map((position) => {
    return Math.round(position);
  });

  return ticks;
};

export const revenueChangeIcon = (revenueChangePercent: number) => {
  if (revenueChangePercent > 0) {
    return arrowUp;
  } else if (revenueChangePercent < 0) {
    return arrowDown;
  }
  return undefined;
};
