export interface AuthTokenUser {
  id: string;
  fname: string;
  lname: string;
  email: string;
}

export interface AuthTokenCompany {
  id: string;
  name: string;
  type: string;
}

export interface AuthInfo {
  user: AuthTokenUser;
  company: AuthTokenCompany;
}

export interface IssueMentioned {
  reviewIds: string[];
  asin: string;
  token: string;
  occurrences: number;
  avgRatings: number;
}

export interface BrandSummaryResult {
  units_sold: number;
  total_sales: number;
}

export interface BrandSummaryWeeklyAggregateQueryResult {
  week: string;
  units_sold: number;
  total_sales: number;
  third_party_revenue: number;
  revenue: number;
}

export interface ItemSalesTableQueryResult {
  asin: string;
  brand: string;
  product_title: string;
  price: number;
  price_change: number;
  revenue: number;
  units_sold: number;
  revenue_change: number;
}

export interface SalesItem {
  asin: string;
  week: string;
  brand: string;
  productTitle: string;
  price: number;
  priceChange: number;
  revenue: number;
  unitsSold: number;
  revenueChange: number;
}

export interface BrandSummaryWeeklyAggregateItem {
  week: string;
  unitsSold: number;
  totalSales: number;
  thirdPartyRevenue: number;
  revenue: number;
}

export interface BrandSummary {
  unitsSold: number;
  totalSales: number;
  brand: string;
  unitsSoldPercentChange: number;
  totalSalesPercentChange: number;
  data: BrandSummaryWeeklyAggregateItem[];
}

export type AverageData = {
  positive: number[];
  negative: number[];
};

export interface PeriodRatingDistribution {
  periodStart: string;
  periodEnd: string;
  positive: number;
  negative: number;
  allPositive?: number;
  allNegative?: number;
}

export interface TrendDataInterface {
  date: string;
  value: number;
}

export interface RatingAndSalesTrendDataInterface {
  ratings: TrendDataInterface[];
}

export interface ReviewsSnapshotInterface {
  totalRatings: number;
  averageRatings: number;
  previousTotal?: number;
  previousAverage?: number;
  ratings: { star1: number; star2: number; star3: number; star4: number; star5: number };
  ratingsHistory: Array<{ period: number; totalRating: number; averageRating: number; week: string }>;
}

export interface XYDataPoint {
  x: number;
  y: number;
  week?: string;
}

export interface RatingSummaryData {
  star1: number;
  star2: number;
  star3: number;
  star4: number;
  star5: number;
}

export interface MainReviewsSnapshotInterface {
  reviewsCount: number;
  averageRating: number;
  averageRatingPercentChanged: number;
  reviewsCountPercentChanged: number;
  reviewRatingSummaryData: RatingSummaryData;
  ratingsHistoricalData: XYDataPoint[];
  avgRatingHistoricalData: XYDataPoint[];
}

export interface MainRatingDistributionInterface {
  data: AverageData;
  xData: number[];
  totalNegative: number;
  totalPositive: number;
}

export interface KPIData {
  seller: number;
  vendor: number;
}

export interface DashboardSnapshot {
  periodEndDate: string;
  sales: KPIData;
  units: KPIData;
}

export interface Revenue {
  asin: string;
  orderedRevenue: number;
  week: string;
}

export interface Unit {
  asin: string;
  unitsOrdered: number;
  week: string;
}

export interface CentralUnitsOrdered {
  asin: string;
  weeks: Unit[];
}

export interface CentralRevenueOrdered {
  asin: string;
  weeks: Revenue[];
}

export interface Periods {
  endDate: string;
  quadId: number;
  getPriorPeriod?: {
    endDate: string;
    quadId: number;
  };
}

export interface QuadPeriods {
  quadId: number;
  endDate: string;
  dates: string[];
}

export interface ReviewByAsinOption {
  sort: {
    field: string;
    value: 'asc' | 'desc';
  };
  star?: {
    operator: 'lte' | 'gte' | 'equals';
    value: 1 | 2 | 3 | 4 | 5;
  };
}

export interface GetAsinsOption {
  sort: {
    field: string;
    value: 'asc' | 'desc';
  };
  star?: {
    lowerBound?: number;
    upperBound?: number;
  };
}

export interface ReviewByAsinResponse {
  total: number;
  reviews: ReviewWithNotes[];
}

export interface Review {
  review_id: string;
  seller_id: string;
  asin: string;
  review_title?: string;
  review?: string;
  review_date: string;
  stars?: number;
  found_helpful: number;
  image_links?: string;
  video_links?: string;
  review_url?: string;
}

export interface ReviewWithNotes extends Review {
  note?: Note;
}

export interface Note {
  id: string;
  note?: string;
  is_analyzed: boolean;
  updated_by: string;
  created_at: Date;
  updated_at: Date;
  review_id: string;
}

export interface AsinReviewDashboard {
  asin: string;
  totalRatings: number;
  averageRating: number;
  negativeReviews: number;
}

export interface GetAsinResponse {
  total: number;
  asins: AsinReviewDashboard[];
}

export interface NoteRequest {
  isAnalyzed?: boolean;
  note?: string;
  reviewId: string;
}

export interface AsinRating {
  total: number;
  average: number;
  negative: number;
}

export interface AsinReviewSummaryItem {
  asin: string;
  brand: string;
  productTitle: string;
  unitsSold: number;
  total: number;
  average: number;
  negative: number;
}

export interface AsinItemRating {
  asin: string;
  brand: string;
  productTitle: string;
  unitsSold: number;
  rating: AsinRating;
}

export interface AsinItem {
  asin: string;
  brand: string;
  productTitle: string;
  productUrl: string;
  imageUrls: string[];
}

export interface AsinsItemDetail extends AsinItemRating {
  productUrl: string;
  productImageUrls: string[];
}

export interface AsinsReviewDetail {
  asin: string;
  rating?: AsinRating;
  productImageUrls?: string[];
}

export type AsinsItemDetailUI = Omit<AsinsItemDetail, 'rating'> & AsinRating;
export type AsinItemDetailWithUnitSold = AsinsItemDetailUI & { unitsSold: number };

export interface UnitsOrderedWeek {
  week: string;
  unitsOrdered: number;
}

export interface UnitsOrdered {
  asin: string;
  weeks: UnitsOrderedWeek[];
}

export interface Sales {
  asin: string;
  data: SalesWeek[];
}

export interface UnitsSold {
  week: string;
  unitsSold: number;
}

export interface ThirdPartySales {
  asin: string;
  data: {
    week: string;
    avgPrice: number;
    totalPUnits: number;
    totalPRevenue: number;
  }[];
}

export interface SellerSales {
  endDate: string;
  revenue: number;
  totalPRevenue: number;
  total3pRevenue: number;
  totalPUnits: number;
  total3pUnits: number;
}

export interface RawSellerSales {
  weeks: SellerSales[];
}

export interface RawExclusiveSales {
  weeks: ExclusiveSales[];
}

export interface ExclusiveSales {
  asin: string;
  totalPUnits: number;
  endDate: string;
}

export interface HighestLowest {
  highestItem: AsinsReviewDetail;
  lowestItem: AsinsReviewDetail;
}

export interface SellerCentralUnitsSold {
  weeks: {
    asin: string;
    totalPUnits: number;
    week: string;
  }[];
}
export interface SalesWeek {
  week: string;
  orderedRevenue: number;
}

export interface OutOfStock {
  asin: string;
  weeks: OutOfStockWeek[];
}
export interface OutOfStockWeek {
  week: string;
  outOfStock: number;
}

export interface SdnoApp {
  id: string;
  name: string;
}

export interface SdnoApps {
  val: SdnoApp[];
}

export interface SdnoCompany {
  id: string;
  apps: SdnoApps;
}

export interface SdnoUser {
  id: string;
  apps: SdnoApps;
}

export interface SdnoAuthPayload {
  company: SdnoCompany;
  user: SdnoUser;
}

export interface ItemSalesByProduct {
  asin: string;
  productTitle: string;
  brand: string;
  unitsSold: number;
  averagePrice: number;
  revenue: number;
  revenueChange: number;
}
