export const formatText = (text: string) => {
  const split = text.split(':');

  return split.length === 2 ? (
    <>
      <span>{`${split[0]}:`.trim()}</span>
      &nbsp;
      <span>{split[1].trim()}</span>
    </>
  ) : (
    <span>{text}</span>
  );
};
