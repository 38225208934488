export const SpinsInternalCompanyId = 'SPINS_INTERNAL';
export const SpinsInternalCompanyName = 'Spins Internal';
export const SpinsInternalUsersFilename = 'spins-internal-users.json';
export const SpinsBetaUsersFilename = 'spins-beta-users.json';
export const SpinsDataGroupsFilename = 'spins-data-groups.json';
export const POWERBI_USERNAME = 'Future';
export const SomeVendorId = 'amzn1.vg.1955820';
export const DsaAppId = 'a56Dm000000XxtXIAS';

/////////////////////
// Review Tables
export const TblReviews = 'reviews';
export const TblNegativeSentiments = 'negative_sentiments';
export const TblTopIssues = 'top_issues';
export const TblTopIssueReviews = 'top_issue_reviews';
export const ReviewsVersionedTables = [TblReviews, TblNegativeSentiments, TblTopIssues, TblTopIssueReviews];

/////////////////////
// Sales Tables
export const TblAsins = 'asins';
export const TblEntityBrandMappings = 'entity_brand_mappings';
export const TblAsinWeekly = 'asin_weekly';
export const TblEntityWeekly = 'entity_weekly';
export const TblEntityAsinWeekly = 'entity_asin_weekly';
export const TblAsinWeekly3P = 'asin_weekly_3p';
export const TblBrandTaskIndex = 'brand_task_index';

export const SalesVersionedTables = [
  TblAsins,
  TblEntityBrandMappings,
  TblEntityWeekly,
  TblEntityAsinWeekly,
  TblAsinWeekly3P,
  TblBrandTaskIndex,
];

export const PERIOD_COUNT = 13;
export const PERIOD_MAP = { QUAD: 4, WEEK: 1 };
