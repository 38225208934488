import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { ColumnType } from '../components/review-details-table/review-details-table';

export interface Config {
  recordKey: string | recordKeyObject;
  direction?: string;
}

export type recordKeyObject = {
  objKey: string; // Key of the object in the `T` type
  propKey: string;
};

export function sortingFunction(valueA: unknown, valueB: unknown, direction: string | undefined): number {
  // Check for empty strings and falsy values
  if (direction === 'descending') {
    if (!valueA || valueA === '' || valueA === undefined) return 1; // Move a to the end
    if (!valueB || valueB === '' || valueB === undefined) return -1; // Move b to the end
  }

  if (typeof valueA === 'number' && typeof valueB === 'number') {
    // Both values are numbers
    return direction === 'ascending' ? valueA - valueB : valueB - valueA;
  } else if (typeof valueA === 'number') {
    // a is a number, b is a string
    return direction === 'ascending' ? -1 : 1;
  } else if (typeof valueB === 'number') {
    // a is a string, b is a number
    return direction === 'ascending' ? 1 : -1;
  } else {
    // Both values are strings
    return direction === 'ascending'
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  }
}

export function sortBooleanColumn(valueA: unknown, valueB: unknown, direction: string | undefined): number {
  const a = !!valueA;
  const b = !!valueB;
  if (a === b) {
    return 0;
  }
  if (direction === 'ascending') {
    return a < b ? -1 : 1;
  }
  return b < a ? -1 : 1;
}

export function useSortColumns<T>(data: T[], config: Config, columnType: ColumnType) {
  const [sortConfig, setSortConfig] = useState<Config>(config);
  const [type, setType] = useState<ColumnType>(columnType);
  const sortedItems = useMemo(() => {
    return data.sort((a, b) => {
      let valueA, valueB;
      let key,
        objectKey = '';
      if (typeof sortConfig.recordKey === 'string') {
        key = sortConfig.recordKey;
      } else {
        key = sortConfig.recordKey.propKey;
        objectKey = sortConfig.recordKey.objKey;
      }

      if (type === ColumnType.OBJECT) {
        valueA = (a as never)[objectKey as keyof T]?.[key];
        valueB = (b as never)[objectKey as keyof T]?.[key];
        return sortingFunction(valueA, valueB, sortConfig.direction);
      } else if (key === 'is_analyzed') {
        valueA = (a as never)[objectKey as keyof T]?.[key];
        valueB = (b as never)[objectKey as keyof T]?.[key];
        return sortingFunction(valueA, valueB, sortConfig.direction);
      } else if (type === ColumnType.LINK) {
        valueA = a['image_links' as keyof T] || a['video_links' as keyof T];
        valueB = b['image_links' as keyof T] || b['video_links' as keyof T];
        return sortingFunction(valueA, valueB, sortConfig.direction);
      } else {
        valueA = a[key as keyof T];
        valueB = b[key as keyof T];
        return sortingFunction(valueA, valueB, sortConfig.direction);
      }
    });
  }, [data, sortConfig, type]);

  const requestSort = (key: string | recordKeyObject, type: ColumnType) => {
    let direction = 'ascending';
    if (
      typeof sortConfig.recordKey === 'object' &&
      !Array.isArray(sortConfig.recordKey) &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    if (sortConfig && sortConfig.recordKey === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ recordKey: key, direction });
    setType(type);
  };

  const CaretIcon = ({ recordKey }: Config) => {
    const isAscending = sortConfig.direction === 'ascending';
    if (typeof sortConfig.recordKey === 'string' && sortConfig.recordKey === recordKey) {
      return isAscending ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />;
    } else if (
      typeof sortConfig.recordKey === 'object' &&
      !Array.isArray(sortConfig.recordKey) &&
      JSON.stringify(sortConfig.recordKey) === JSON.stringify(recordKey)
    ) {
      /* istanbul ignore next */
      return isAscending ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />;
    }

    return <FontAwesomeIcon icon={faCaretDown} />;
  };

  return { items: sortedItems, requestSort, sortConfig, CaretIcon };
}
