import { gql } from '@apollo/client';
import {
  AsinItem,
  BrandSummary,
  HighestLowest,
  IssueMentioned,
  Note,
  OutOfStock,
  PeriodRatingDistribution,
  RatingAndSalesTrendDataInterface,
  ReviewByAsinResponse,
  ReviewsSnapshotInterface,
  SalesItem,
  SdnoAuthPayload,
  UnitsSold,
} from '@dsa-ui/dsa-shared';

export interface IssuesMentionedResult {
  dsaIssuesMentioned: IssueMentioned[];
}

export interface RatingDistributionForAsinResult {
  dsaRatingDistributionForAsin: PeriodRatingDistribution[];
}

export interface RatingDistributionResult {
  dsaRatingDistribution: PeriodRatingDistribution[];
}

export interface ReviewsSnapshotForAsinResult {
  dsaReviewsSnapshotForAsin: ReviewsSnapshotInterface;
}

export interface ReviewsSnapshotResult {
  dsaReviewsSnapshot: ReviewsSnapshotInterface;
}

export interface RatingAndSalesTrendDataResult {
  dsaRatingAndSalesTrendData: RatingAndSalesTrendDataInterface;
}

export interface ReviewByAsinResult {
  dsaGetReviewsByAsin: ReviewByAsinResponse;
}

export interface GetAsinsResult {
  dsaGetAllAsins: AsinItem[];
}

export interface UpsertNoteResult {
  dsaUpsertNote: Note;
}

export interface Asin {
  asin: string;
}

export interface Week {
  week: string;
}

export interface AsinsReviewDetailResult {
  dsaGetReviewHighlights: HighestLowest;
}

export const MainReviewsSnapshotQuery = gql`
  query DsaMainReviewsSnapshotQuery($endDate: String!, $brand: String!, $period: String!, $companyId: String!) {
    dsaReviewsSnapshot(endDate: $endDate, brand: $brand, period: $period, companyId: $companyId) {
      totalRatings
      averageRatings
      previousTotal
      previousAverage
      ratings {
        star1
        star2
        star3
        star4
        star5
      }
      ratingsHistory {
        period
        totalRating
        averageRating
        week
      }
    }
  }
`;
export const MainRatingDistributionQuery = gql`
  query MainRatingDistributionQuery($brand: String!, $period: String, $endDate: String!, $companyId: String!) {
    dsaRatingDistribution(brand: $brand, period: $period, endDate: $endDate, companyId: $companyId) {
      periodStart
      periodEnd
      positive
      negative
      allPositive
      allNegative
    }
  }
`;

export const IssuesMentionedQuery = gql`
  query DsaIssuesMentioned($asin: String!, $companyId: String!) {
    dsaIssuesMentioned(asin: $asin, companyId: $companyId) {
      reviewIds
      asin
      token
      occurrences
      avgRatings
    }
  }
`;

export const RatingDistributionQuery = gql`
  query DsaRatingDistribution(
    $brand: String!
    $asin: String!
    $period: String!
    $endDate: String!
    $companyId: String!
  ) {
    dsaRatingDistributionForAsin(
      brand: $brand
      asin: $asin
      period: $period
      endDate: $endDate
      companyId: $companyId
    ) {
      periodEnd
      negative
      positive
      periodStart
      allPositive
      allNegative
    }
  }
`;

export const ReviewsSnapshotQuery = gql`
  query DsaReviewsSnapshotForAsin(
    $endDate: String!
    $brand: String!
    $asin: String!
    $period: String!
    $companyId: String!
  ) {
    dsaReviewsSnapshotForAsin(endDate: $endDate, brand: $brand, asin: $asin, period: $period, companyId: $companyId) {
      totalRatings
      averageRatings
      previousTotal
      previousAverage
      ratings {
        star1
        star2
        star3
        star4
        star5
      }
      ratingsHistory {
        period
        totalRating
        averageRating
        week
      }
    }
  }
`;
// noinspection GraphQLUnresolvedReference

export const RatingAndSalesTrendDataQuery = gql`
  query DsaRatingAndSalesTrendData($brand: String!, $period: String!, $sellerId: String!, $companyId: String!) {
    dsaRatingAndSalesTrendData(brand: $brand, period: $period, sellerId: $sellerId, companyId: $companyId) {
      ratings {
        date
        value
      }
    }
  }
`;

export const GetReviewsByAsin = gql`
  query DsaGetReviewsByAsin($asin: String!, $brand: String!, $companyId: String!) {
    dsaGetReviewsByAsin(asin: $asin, brand: $brand, companyId: $companyId) {
      reviews {
        review_id
        brand
        asin
        review_title
        review
        review_date
        stars
        found_helpful
        image_links
        video_links
        note {
          id
          note
          is_analyzed
          updated_by
          created_at
          updated_at
          review_id
        }
        review_url
      }
      total
    }
  }
`;

export const GetAsins = gql`
  query DsaGetAllAsins($brand: String!, $companyId: String!) {
    dsaGetAllAsins(brand: $brand, companyId: $companyId) {
      asin
      brand
      productTitle
      imageUrls
      productUrl
    }
  }
`;

export const UpsertNoteMutation = gql`
  mutation DsaUpsertNote($data: NoteRequest!, $companyId: String!) {
    dsaUpsertNote(data: $data, companyId: $companyId) {
      note
      is_analyzed
      updated_at
    }
  }
`;

export interface GetUnitSoldQueryResult {
  dsaAsinUnitsSold: UnitsSold[];
}

export const GetUnitSoldQuery = gql`
  query DsaAsinUnitsSold($asin: String!, $period: String!, $endDate: String!, $companyId: String!) {
    dsaAsinUnitsSold(asin: $asin, period: $period, endDate: $endDate, companyId: $companyId) {
      unitsSold
      week
    }
  }
`;

export interface GetOutOfStockSellerResult {
  getSellerOutOfStock: OutOfStock[];
}

export const GetOutOfStockSellerQuery = gql`
  query GetSellerOutOfStock($sellerId: String!, $week: String) {
    getSellerOutOfStock(vendorId: $sellerId, week: $week) {
      asin
      weeks {
        outOfStock
        week
      }
    }
  }
`;

export interface GetUserAuthPayloadResult {
  sdnoAuthPayload: SdnoAuthPayload;
}

export const GetUserAuthPayload = gql`
  query DsaSdnoAuthPayload {
    sdnoAuthPayload {
      company {
        apps {
          val {
            id
            name
          }
        }
        id
      }
      user {
        apps {
          val {
            id
            name
          }
        }
        id
      }
    }
  }
`;

export const GetReviewHighlightsQuery = gql`
  query DsaGetReviewHighlights($brand: String!, $endDate: String!, $companyId: String!) {
    dsaGetReviewHighlights(brand: $brand, endDate: $endDate, companyId: $companyId) {
      highestItem {
        asin
        rating {
          total
          average
          negative
        }
        productImageUrls
      }
      lowestItem {
        asin
        rating {
          total
          average
          negative
        }
        productImageUrls
      }
    }
  }
`;

export const GetSBBCountQuery = gql`
  query GetSellerSuppressedBuyBoxCount($sellerId: String!, $endDate: String!) {
    getSellerSuppressedBuyBoxCount(sellerId: $sellerId, endDate: $endDate) {
      sellerId
      endDate
      sbbCount
    }
  }
`;

export interface GetSBBCountQueryResult {
  getSellerSuppressedBuyBoxCount: {
    sellerId: string;
    endDate: string;
    sbbCount: number;
  };
}

export const DsaAsinSales = gql`
  query DsaAsinSales($brand: String!, $endDate: String!, $period: String!, $companyId: String!) {
    dsaAsinSales(brand: $brand, endDate: $endDate, period: $period, companyId: $companyId) {
      asin
      week
      brand
      productTitle
      price
      priceChange
      revenue
      unitsSold
      revenueChange
    }
  }
`;

export interface DsaAsinSalesResult {
  dsaAsinSales: SalesItem[];
}

export const dsaBrandSalesSummary = gql`
  query DsaBrandSalesSummary($brand: String!, $endDate: String!, $period: String!, $companyId: String!) {
    dsaBrandSalesSummary(brand: $brand, endDate: $endDate, period: $period, companyId: $companyId) {
      unitsSold
      totalSales
      brand
      data {
        week
        unitsSold
        totalSales
        thirdPartyRevenue
        revenue
      }
      totalSalesPercentChange
      unitsSoldPercentChange
    }
  }
`;

export interface DsaBrandSalesSummaryResult {
  dsaBrandSalesSummary: BrandSummary;
}

export const GetAsinsReviewSummary = gql`
  query DsaAsinsReviewSummary($brand: String!, $endDate: String!, $companyId: String!) {
    result: dsaAsinsReviewSummary(brand: $brand, endDate: $endDate, companyId: $companyId) {
      asin
      brand
      productTitle
      unitsSold
      total
      average
      negative
    }
  }
`;

export interface AsinsReviewSummaryResult {
  result: AsinsReviewSummaryItem[];
}

export interface AsinsReviewSummaryItem {
  asin: string;
  brand: string;
  productTitle: string;
  unitsSold: number;
  total: number;
  average: number;
  negative: number;
}
