import { logger } from '../../logging';
import { timer } from '@dsa-ui/dsa-shared';
import { SetterOrUpdater } from 'recoil';
import { getTime } from './dateTimeUtils';
import { getHtmlToPdf } from './html2pdfWrapper';

export const createPdf = async (pageName: string, updateReflowHighcharts: SetterOrUpdater<number>) => {
  logger.info('Creating PDF...');

  const element = document.getElementById('mainContent');
  if (!element) {
    return;
  }

  element.classList.add('pdf-export');
  updateReflowHighcharts(getTime());
  await timer(1000);

  const filename = `${pageName}-${getTime()}.pdf`;

  const opt = {
    margin: 0.1,
    filename,
    pagebreak: { mode: 'avoid-all', before: '.pdf-page-break-before' },
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
  };

  getHtmlToPdf().set(opt).from(element).save();

  element.classList.remove('pdf-export');
  updateReflowHighcharts(getTime());

  logger.info(`Pdf [${filename} created.`);
};
