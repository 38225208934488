import styles from './dashboard-reviews.module.scss';
import { useRecoilValueLoadable } from 'recoil';
import { mainReviewsSnapshotState } from '../../state/reviewsPage';
import { Row } from 'react-bootstrap';
import { hasValue } from '../../utils/recoilUtils';
import Reviews from '../reviews/reviews';
import { InlineLoadingSpinner } from '../inline-loading-spinner/inline-loading-spinner';
import React from 'react';

export function DashboardReviews() {
  const mainReviewsSnapshotResult = useRecoilValueLoadable(mainReviewsSnapshotState);

  if (!hasValue(mainReviewsSnapshotResult) || !mainReviewsSnapshotResult.contents) {
    return <InlineLoadingSpinner />;
  }
  const performanceIndicator = mainReviewsSnapshotResult.contents.averageRating > 3 ? 'Good' : 'Bad';

  return (
    <div className={styles['review-container']}>
      <Row>
        <p className={styles['summary']}>
          Your review performance is
          <span className={styles[performanceIndicator.toLowerCase()]}> {performanceIndicator}! </span>
        </p>
      </Row>
      <Row>
        <Reviews {...mainReviewsSnapshotResult.contents} />
      </Row>
    </div>
  );
}

export default DashboardReviews;
