import styles from './sales-snapshot.module.scss';
import SummaryCard from '../summary-card/summary-card';
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '@spins-gcp/shared-components-react';
import { useRecoilValueLoadable } from 'recoil';
import { hasValue } from '../../utils/recoilUtils';
import { BrandSummaryWeeklyAggregateItem } from '@dsa-ui/dsa-shared';
import { InlineLoadingSpinner } from '../inline-loading-spinner/inline-loading-spinner';
import React from 'react';
import { salesSellerState } from '../../state/sales';
import { formatSales } from '../../utils/currencyUtils';

export function SalesSnapshot() {
  const sellerSales = useRecoilValueLoadable(salesSellerState);
  const hasSellerSales = hasValue(sellerSales);

  const graphData = hasSellerSales ? sellerSales.contents.data : [];
  return (
    <>
      <Col md={4} s={4} xs={4} className="p-2">
        {hasSellerSales ? (
          <SummaryCard
            title={'Units Sold'}
            value={formatNumber(sellerSales.contents.unitsSold, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            percentChange={sellerSales.contents.unitsSoldPercentChange}
            graphData={[...graphData].reverse().map((x: BrandSummaryWeeklyAggregateItem, index: number) => {
              return { x: index, y: x.unitsSold, week: x.week, title: 'Units' };
            })}
          />
        ) : (
          <div className={styles['container']}>
            <InlineLoadingSpinner />
          </div>
        )}
      </Col>
      <Col md={4} s={4} xs={4} className="p-2">
        {hasSellerSales ? (
          <SummaryCard
            title={'Sales'}
            value={`${formatSales(sellerSales.contents.totalSales / 1000)}K`}
            percentChange={sellerSales.contents.totalSalesPercentChange}
            graphData={[...graphData].reverse().map((x: BrandSummaryWeeklyAggregateItem, index: number) => {
              return { x: index, y: x.totalSales, title: 'Sales', week: x.week };
            })}
          />
        ) : (
          <div className={styles['container']}>
            <InlineLoadingSpinner />
          </div>
        )}
      </Col>
      <Col md={4} s={4} xs={4} className="p-2">
        <div
          style={{
            background: '#ffffff',
            borderRadius: '6px',
            padding: '1rem',
            whiteSpace: 'nowrap',
            height: '132px',
          }}
        >
          <Row>
            <div style={{ fontSize: '14px', fontWeight: 500 }}>Lost Sales</div>
          </Row>
          <Row className={'align-items-baseline'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '86px',
              }}
            >
              <span className={styles['coming-soon']}>Coming Soon!</span>
            </div>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default SalesSnapshot;
