import { atom, selector } from 'recoil';
import { endDateState } from './period';
import { querySdn } from '../utils/sdnClient';
import { GetSBBCountQuery, GetSBBCountQueryResult } from './queries';
import { logger } from '../../logging';
import { userSellerIdState } from './user';

export const suppressedBuyBoxState = atom({
  key: 'suppressedBuyBox',
  default: selector<number>({
    key: 'suppressedBuyBoxCount',
    get: ({ get }) => {
      const endPeriod = get(endDateState);
      const sellerId = get(userSellerIdState);

      if (!sellerId) {
        logger.info('Seller id is undefined');
        return 0;
      }

      return querySdn<GetSBBCountQueryResult>({
        query: GetSBBCountQuery,
        variables: { sellerId: sellerId, endDate: endPeriod },
      })
        .then((res) => res.data.getSellerSuppressedBuyBoxCount.sbbCount)
        .catch((e) => {
          logger.error(e);
          return 0;
        });
    },
  }),
});
