import styles from './item-sales-table.module.scss';
import { filterArrayByKeys, scaleTableWidth } from '../../utils/helperUtils';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Table } from 'react-bootstrap';
import { SalesItem } from '@dsa-ui/dsa-shared';
import { useTable } from '../../hooks/useTable';
import { formatNumber, ChangeBadge } from '@spins-gcp/shared-components-react';
import TableFooter from '../rating-table/table-footer/table-footer';
import Highlighted from '../highlighted/highlighted';
import { useSortColumns } from '../../hooks/useSortColumns';
import { ColumnType } from '../review-details-table/review-details-table';
import { revenueChangeIcon } from '../../utils/chartUtils';
import { useRecoilValue } from 'recoil';
import { activePeriodState } from '../../state/period';

/* eslint-disable-next-line */
export interface ItemSalesTableProps {
  asins: SalesItem[];
  term: string;
}

export function ItemSalesTable({ asins, term }: ItemSalesTableProps) {
  const filteredAsins = filterArrayByKeys(asins, term, ['asin', 'productTitle']);
  const period = useRecoilValue(activePeriodState);
  const priorText = period === 'QUAD' ? ' vs prior 4 weeks' : 'vs prior week';
  const [tableWidth, setTableWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const { items, requestSort, CaretIcon } = useSortColumns<SalesItem>(
    filteredAsins,
    {
      recordKey: 'ASIN',
      direction: 'ascending',
    },
    ColumnType.STRING
  );
  useEffect(() => {
    if (tableRef) {
      setTableWidth(tableRef?.current?.clientWidth || 0);
    }
  }, [tableRef]);

  const headers = [
    {
      name: 'ASIN',
      width: 126,
      field: 'asin',
      type: ColumnType.STRING,
    },
    {
      name: 'Product Title',
      width: 217,
      field: 'productTitle',
      type: ColumnType.STRING,
    },
    {
      name: 'Brand',
      width: 108,
      field: 'brand',
      type: ColumnType.STRING,
    },
    {
      name: 'Units Sold',
      width: 76,
      field: 'unitsSold',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Average Price',
      width: 88,
      field: 'price',
      type: ColumnType.NUMBER,
    },
    {
      name: 'Revenue',
      width: 84,
      field: 'revenue',
      type: ColumnType.NUMBER,
    },

    {
      name: 'Revenue % Change',
      width: 110,
      field: 'revenueChange',
      type: ColumnType.NUMBER,
    },
  ];

  const totalColumnWidth = headers.reduce((total, col) => total + col.width, 0);

  const { page, setRowsPerPage, nextPage, prevPage, paginated, maxPage, setPage } = useTable<SalesItem>(items);

  useEffect(() => {
    if (!!term) {
      setPage(1);
    }
  }, [term, setPage]);
  return (
    <div className={styles['item-sales-table']}>
      <Table className={`${styles['table']} table-bordered`} ref={tableRef}>
        <thead>
          <tr className={`${styles['header']}`}>
            {headers.map((h) => {
              return (
                <th
                  key={h.name}
                  className={styles['left']}
                  data-testid={`header-${h.name}`}
                  style={{ width: scaleTableWidth(h.width, tableWidth, totalColumnWidth) }}
                  onClick={() => h.field && requestSort(h.field, h.type)}
                >
                  <div>
                    <span>{h.name}</span> {h.field && <CaretIcon recordKey={h.field} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {paginated.length === 0 ? (
            <tr>
              <td colSpan={8}>No data found</td>
            </tr>
          ) : (
            paginated.map((item, index) => {
              const icon = revenueChangeIcon(item.revenueChange);

              return (
                <tr key={index} className={styles['nth-child']}>
                  <td className={styles['left']}>
                    <Highlighted textToHighlight={term}>{item.asin}</Highlighted>
                  </td>
                  <td className={`${styles['left']} ${styles['scrolling-cell']}`} data-testid={`title-${index}`}>
                    <Highlighted textToHighlight={term}>{item.productTitle}</Highlighted>
                  </td>
                  <td className={styles['left']}>
                    <Highlighted textToHighlight={term}>{item.brand}</Highlighted>
                  </td>
                  <td className={styles['right']}>
                    {formatNumber(item.unitsSold, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                  </td>
                  <td className={styles['right']}>
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      delay={{ show: 250, hide: 400 }}
                      defaultShow={false}
                      overlay={
                        <div className={styles['tooltip']}>
                          <>
                            <ChangeBadge value={item.priceChange} decimals={0} endingSign={'%'} /> {priorText}
                          </>
                        </div>
                      }
                      placement={'top'}
                      flip={false}
                      onHide={undefined}
                      onToggle={undefined}
                      popperConfig={undefined}
                      show={undefined}
                      target={undefined}
                    >
                      <span className={styles['cell']}>
                        {formatNumber(item.price, {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td className={styles['right']}>
                    {formatNumber(item.revenue, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                  </td>
                  <td className={styles['right']}>
                    {icon && <img src={icon} className={styles['arrow-icon']} alt={'arrow'} />}
                    {formatNumber(item.revenueChange, {
                      maximumFractionDigits: 1,
                      minimumFractionDigits: 0,
                      signDisplay: 'never',
                    }) + '%'}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <div className={styles['footer']}>
        <TableFooter
          maxPage={maxPage}
          nextPage={nextPage}
          previousPage={prevPage}
          currentPage={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

export default ItemSalesTable;
