import styles from './item-row.module.scss';
import { Col, Row } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import React from 'react';
import { formatNumber } from '@spins-gcp/shared-components-react';
import { Star } from 'react-feather';

/* eslint-disable-next-line */
export interface ItemRowProps {
  mention: string;
  term?: string;
  itemCount: number;
  starCount: number;
  onClick: (value: string) => void;
}

export function ItemRow({ mention, term, itemCount, starCount, onClick }: ItemRowProps) {
  const starRating =
    starCount % 1
      ? formatNumber(starCount, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
      : formatNumber(starCount, { maximumFractionDigits: 0, minimumFractionDigits: 0 });

  return (
    <Row className={styles['item-row']}>
      <Col className="p-0 d-flex align-items-center" xs={4}>
        <div className={`${styles['item-mention']} ${term === mention ? styles['selected'] : ''}`}>
          <div onClick={() => onClick(mention)}>{mention}</div>
        </div>
      </Col>
      <Col className="p-0 text-center" xs={3}>
        <span className={styles['item-count']}>{itemCount}</span>
      </Col>
      <Col className={styles['star']} xs={5}>
        <Rating
          initialValue={starCount}
          readonly={true}
          size={14}
          allowFraction={true}
          fillIcon={<Star color={'#F1A103'} fill={'#F1A103'} size={16} />}
          emptyIcon={<Star color={'#B1B1B1'} fill={'#B1B1B1'} size={16} />}
        />
        <span className={styles['star-rating-value']}>{starRating}</span>
      </Col>
    </Row>
  );
}

export default ItemRow;
