import styles from './rating-distribution.module.scss';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import { PercentChangeBadge } from '@spins-gcp/shared-components-react';
import React from 'react';
import { HighchartsWrapper } from '../highcharts-wrapper/highcharts-wrapper';
import Highcharts from 'highcharts';
import { formatNumberToKMB } from '../../utils/numberUtils';
import { AverageData } from '@dsa-ui/dsa-shared';
import { useRecoilValue } from 'recoil';
import { activePeriodState } from '../../state/period';
import { labelFormatter } from '../../utils/highchartsUtils';

/* eslint-disable-next-line */
export interface RatingDistributionProps {
  data: AverageData;
  xData?: number[];
  totalNegative: number;
  totalPositive: number;
}

export function RatingDistribution({ data, xData, totalNegative, totalPositive }: RatingDistributionProps) {
  const title = useRecoilValue(activePeriodState) === 'QUAD' ? '4 Weeks' : '1 Week';
  const averageOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: true,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      outside: true,
      className: styles['tooltip'],

      backgroundColor: 'transparent',
      formatter: /* istanbul ignore next */ function () {
        const content = `<span>${Highcharts.dateFormat('%B %d, %Y', parseInt(this.point.category as string))}</span>
        <div class="${styles['tooltip-label']}">
          <div>
            <span class="${styles['tooltip-icon']}" style="background-color: ${
          this.point.color
        };"> &nbsp;&nbsp;&nbsp; </span>
            <span>${this.point.series.name}</span>
          </div>
          <span class="${styles['value']}">${formatNumberToKMB(this.point.y as number)}</span>
        </div>`;

        return `<div class='${styles['tooltip-title']}'>` + content + '</div>';
      },
    },

    xAxis: {
      type: 'datetime',
      categories: xData?.map((x) => x.toString()),
      title: {
        text: `${title} Ending`,
        style: {
          textAlign: 'center',
          fontSize: '10px',
          fontFamily: 'Public Sans',
        },
      },
      labels: {
        useHTML: true,
        formatter: labelFormatter,
        rotation: 0,
        staggerLines: 1,
        padding: 0,
        style: {
          fontSize: '10px',
          fontFamily: 'Public Sans',
          fontWeight: '400px',
          textOverflow: 'none',
          color: '#474747',
        },
      },
    },
    yAxis: {
      allowDecimals: false,
      title: { text: null },
      tickAmount: 3,

      labels: {
        style: {
          fontSize: '10px',
          fontFamily: 'Public Sans',
          fontWeight: '400px',
          color: '#474747',
        },
      },
    },
    plotOptions: {
      column: {
        maxPointWidth: 50,
        borderWidth: 0,
        borderRadius: 2,
        pointWidth: 8,
      },
    },
    chart: {
      height: '175px',
    },
    legend: {
      itemStyle: {
        fontSize: '10',
        fontFamily: 'Public Sans',
        fontWeight: '400',
        textAlign: 'center',
        color: '#474747',
      },
      align: 'center',
      backgroundColor: '#F8F8F8',
      borderRadius: 4,
    },
    series: [
      {
        name: 'Positive',
        type: 'column',
        data: data.positive,
        // data: [23, 6, 23, 4, 5, 7],
        color: '#D69F14',
      },
      {
        name: 'Negative',
        type: 'column',
        data: data.negative,
        // data: [23, 9, 27, 4, 13, 3],
        color: '#FADB7A',
      },
    ],
    credits: { enabled: false },
  };
  const currentPositive = data.positive[data.positive.length - 1];
  const currentNegative = data.negative[data.negative.length - 1];
  const previousPositive = data.positive[data.positive.length - 2] ?? 0;
  const previousNegative = data.negative[data.negative.length - 2] ?? 0;
  const positivePercentageChange =
    (currentPositive - previousPositive) / (previousPositive !== 0 ? previousPositive : 1);
  const negativePercentageChange =
    (currentNegative - previousNegative) / (previousNegative !== 0 ? previousNegative : 1);

  return (
    <div>
      <Row className={'p-1'}>
        <div className={styles['label']}>2 Year Count</div>
        <Col className={styles['positive-negative']}>
          <div>
            <div className={styles['metric-container']}>
              <span className={styles['reviews']}>Positive</span>
              <span className={styles['value']}>{formatNumberToKMB(totalPositive)}</span>
            </div>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              delay={{ show: 250, hide: 400 }}
              defaultShow={false}
              overlay={<div className={styles['overlay-tooltip']}>vs prior {title.toLowerCase()}</div>}
              placement={'top'}
              flip={false}
              onHide={undefined}
              onToggle={undefined}
              popperConfig={undefined}
              show={undefined}
              target={undefined}
            >
              <span>
                <PercentChangeBadge percentage={positivePercentageChange} decimals={0} />
              </span>
            </OverlayTrigger>
          </div>
          <div>
            <div className={styles['metric-container']}>
              <span className={styles['reviews']}>Negative</span>
              <span className={styles['value']}>{formatNumberToKMB(totalNegative)}</span>
            </div>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              delay={{ show: 250, hide: 400 }}
              defaultShow={false}
              overlay={<div className={styles['overlay-tooltip']}>vs prior {title.toLowerCase()}</div>}
              placement={'top'}
              flip={false}
              onHide={undefined}
              onToggle={undefined}
              popperConfig={undefined}
              show={undefined}
              target={undefined}
            >
              <span>
                <PercentChangeBadge percentage={negativePercentageChange} isNegativeMeasure={true} decimals={0} />
              </span>
            </OverlayTrigger>
          </div>
        </Col>
      </Row>
      <div className={'mt-2'}>
        <HighchartsWrapper options={averageOptions} />
      </div>
    </div>
  );
}

export default RatingDistribution;
