import { selectorFamily } from 'recoil';
import { reviewsByAsinState } from './reviewsPage';
import * as R from 'ramda';
import { Note } from '@dsa-ui/dsa-shared';

export const reviewNoteState = selectorFamily<
  { note: string; isAnalyzed: boolean },
  { asin: string; reviewId: string }
>({
  key: 'reviewNoteByAsin',
  get:
    ({ reviewId, asin }) =>
    ({ get }) => {
      const reviewsByAsin = get(reviewsByAsinState({ asin: asin }));
      const review = reviewsByAsin.reviews.find((r) => r.review_id === reviewId);
      return { note: review?.note?.note ?? '', isAnalyzed: !!review?.note?.is_analyzed };
    },
  set:
    ({ asin, reviewId }) =>
    ({ set, get }, newValue) => {
      const reviewsByAsin = get(reviewsByAsinState({ asin: asin }));
      const newAsinResponse = R.clone(reviewsByAsin);
      const reviews = newAsinResponse.reviews;
      const indexToChange = reviews.findIndex((r) => r.review_id === reviewId);
      const typedValue = newValue as { note: string; isAnalyzed: boolean };
      reviews[indexToChange] = {
        ...reviews[indexToChange],
        note: { ...reviews[indexToChange].note, note: typedValue.note, is_analyzed: typedValue.isAnalyzed } as Note,
      };
      set(reviewsByAsinState({ asin: asin }), newAsinResponse);
    },
});
