import styles from './review-details.module.scss';
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AsinDetailsCard from '../../components/asin-details-card/asin-details-card';
import { HighlightCard } from '@spins-gcp/shared-components-react';
import IssuesMentioned from '../../components/issues-mentioned/issues-mentioned';
import ReviewDetailsTable from '../../components/review-details-table/review-details-table';
import SelectablePill from '../../components/selectable-pill/selectable-pill';
import { AsinRatingDistribution } from '../../components/rating-distribution/asin-rating-distribution/asin-rating-distribution';
import { AsinReviewsSnapshot } from '../../components/reviews-snapshot/asin-review-snapshot/asin-reviews-snapshot';
import { useRecoilValueLoadable } from 'recoil';
import { ChevronDown, ChevronUp, Search } from 'react-feather';
import { formatText } from '../../utils/formatTextUtils';
import HeaderFilter from '../../components/header-filter/header-filter';
import { ReviewRequest, reviewsByAsinState } from '../../state/reviewsPage';
import { hasValue } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { userBrandState } from '../../state/user';

export function ReviewDetails() {
  const { asin } = useParams<string>();
  const asinUrl = asin as string;

  const [term, setTerm] = useState<string>('');

  const reviewRequest: ReviewRequest = {
    asin: asinUrl,
  };
  const brand = useRecoilValueLoadable(userBrandState);
  const reviews = useRecoilValueLoadable(reviewsByAsinState(reviewRequest));
  const filterOptions = [
    {
      title: 'Star Rating: All',
      value: undefined,
    },
    {
      title: 'Positive Reviews: 4 to 5 stars',
      value: { lowerBound: 4 },
    },
    { title: 'Negative Reviews: 1 to 3 stars', value: { upperBound: 3 } },
    { title: '5 stars', value: { lowerBound: 5 } },
    { title: '4 stars', value: { lowerBound: 4, upperBound: 4 } },
    { title: '3 stars', value: { lowerBound: 3, upperBound: 3 } },
    { title: '2 stars', value: { lowerBound: 2, upperBound: 2 } },
    { title: '1 star', value: { upperBound: 1 } },
  ];

  const [filterTitle, setFilterTitle] = useState(filterOptions[0]);
  if (!asinUrl || !hasValue(brand)) {
    return <LoadingSpinner />;
  }
  return (
    <Row className={'align-items-start'}>
      <Col className={'p-0'}>
        <Row>
          <div style={{ marginBottom: '20px', marginTop: '20px', height: '30px' }}>
            <div className="d-flex justify-content-between align-items-center">
              <h3 data-testid="breadcrumb-title" className={styles['company-name']}>
                <Link to={'/reviews'} className={styles['breadcrumb']}>
                  Reviews
                </Link>{' '}
                / ASIN {asin}
              </h3>
              <HeaderFilter />
            </div>
          </div>
        </Row>
        <Row>
          <AsinReviewsSnapshot brand={brand.contents} asin={asinUrl} />
        </Row>
        <Row className="p-0" style={{ justifyContent: 'space-between' }}>
          <Col md={6} xs={6} s={6} className="p-2">
            <AsinDetailsCard asin={asinUrl} />
          </Col>
          <Col md={3} xs={3} s={3} className="p-2">
            <HighlightCard
              title={'Rating Distribution'}
              externalStyles={{ flex: '1 1 auto', margin: '0', padding: '0', height: '330px' }}
              childrenExternalStyles={{
                padding: '16px',
              }}
            >
              <AsinRatingDistribution brand={brand.contents} asin={asinUrl} />
            </HighlightCard>
          </Col>
          <Col md={3} xs={3} s={3} className="p-2">
            <HighlightCard
              title={'Issues Mentioned'}
              additionalTitle={<span className={styles['count-label']}>2 Year Trends</span>}
              externalStyles={{ flex: '1 1 auto', margin: '0', padding: '0', height: '330px' }}
              childrenExternalStyles={{
                padding: '16px',
              }}
            >
              <IssuesMentioned asin={asinUrl} setFilter={(value) => setTerm(value)} term={term} />
            </HighlightCard>
          </Col>
        </Row>
        <Row className="p-0">
          <Col className="p-2 px-1">
            <HighlightCard
              title={''}
              externalStyles={{ flex: '1 1 auto', margin: '0', padding: '0', height: '100%' }}
              childrenExternalStyles={{
                padding: '0',
              }}
              additionalTitle={
                <div className={styles['reviews-header']}>
                  <div>
                    <span>Reviews</span>
                    <span>
                      <SelectablePill
                        label={hasValue(reviews) && reviews ? reviews.contents.total.toString() : ''}
                      ></SelectablePill>
                      <span className={styles['count-label']}>2 Year Count</span>
                    </span>
                  </div>
                  <div>
                    <InputGroup size="sm" className={styles['search']}>
                      <InputGroup.Text className={styles['search-icon']}>
                        <Search />
                      </InputGroup.Text>
                      <Form.Control
                        data-testid="search"
                        className={styles['search-text']}
                        type="search"
                        placeholder="Search"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div>
                    <div>
                      {' '}
                      <Dropdown className={styles['filter-container']} data-testid={'star'}>
                        <DropdownButton
                          variant="white"
                          title={
                            <div className={styles['filter-title']}>
                              <div className={styles['format-text']}>{formatText(filterTitle.title)}</div>
                              <ChevronDown size={16} color={'#474747'} />
                            </div>
                          }
                          className={styles['filter-button']}
                          data-testid="star-filter"
                          onSelect={(e) => {
                            const item = JSON.parse(e as string);
                            setFilterTitle(item);
                          }}
                        >
                          <div className={styles['dropdown-menu']}>
                            {[
                              filterTitle,
                              ...filterOptions.filter((options) => options.title !== filterTitle.title),
                            ].map((item, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={JSON.stringify(item)}
                                  className={styles['filter-options']}
                                  key={index}
                                  value={item.title}
                                  data-testid={`star-filter-${index}`}
                                >
                                  <div className={styles['format-text']}>{formatText(item.title)}</div>
                                  {index === 0 && <ChevronUp size={16} color={'#474747'} />}
                                </Dropdown.Item>
                              );
                            })}
                          </div>
                        </DropdownButton>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              }
            >
              <ReviewDetailsTable reviewRequest={reviewRequest} term={term} starFilter={filterTitle.value} />
            </HighlightCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReviewDetails;
