/***
 *
 * @param arr - array to be filtered
 * @param keys - keys where to search. Note: if searching on nested key i.e. contact.email, all the higher parent keys must be included in this case ["contact", "email"]
 * @param searchKey - term to search
 */
export function filterArrayByKeys<T extends object>(arr: T[], searchKey: string, keys?: string[]): T[] {
  return arr.filter((obj) => {
    const searchScope = keys ? keys : Object.keys(obj);
    return searchScope.some((key) => {
      const value = obj[key as keyof T];
      if (value !== null && typeof value === 'object') {
        return filterArrayByKeys([value], searchKey, searchScope).length > 0;
      }
      return value && value.toString().toLowerCase().includes(searchKey.toLowerCase());
    });
  });
}

export function scaleTableWidth(width = 0, tableWidth = 0, totalColumnWidth = 0) {
  if (!tableWidth || !totalColumnWidth) {
    return 0;
  }
  const scalingFactor = tableWidth / totalColumnWidth;
  return width * scalingFactor;
}
