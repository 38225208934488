/* eslint-disable-next-line */
import styles from './side-nav-bar.module.scss';
import { Navbar, NavItem, OverlayTrigger } from 'react-bootstrap';
import arrowLeftIcon from '../../../assets/sidebar/nav-icons/arrow-left.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { ActiveContentRoutes, ComingSoonContentRoutes } from '../../routes/pageRoutes';
import NewFeature from '../new-feature/new-feature';
import { useRecoilState } from 'recoil';
import { isSideNavBarCollapsed } from '../../state/user';
import React from 'react';

export function SideNavBar() {
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useRecoilState(isSideNavBarCollapsed);
  const url = useLocation();
  const collapsedSidebar = isSideBarCollapsed ? `${styles['sidebar']} ${styles['collapsed']}` : '';

  return (
    <Navbar variant="light" className={`${styles['sidebar']} ${collapsedSidebar}`} data-testid="navbar">
      {ActiveContentRoutes.map(({ text, icon, pathname }, index) => {
        const active = url?.pathname === pathname;
        const activeClassName = active ? styles['active'] : '';
        const activeColor = active ? '#E8B227' : '#C7C7C7';

        const Icon = icon;
        return (
          <NavLink key={`active-nav-${index}`} className={`${styles['wrapper']} ${activeClassName}`} to={pathname}>
            {isSideBarCollapsed ? (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                defaultShow={false}
                overlay={<div className={styles['tooltip']}>{text}</div>}
                placement={'right'}
                flip={false}
                onHide={undefined}
                onToggle={undefined}
                popperConfig={undefined}
                show={undefined}
                target={undefined}
              >
                <div>
                  <Icon size={20} color={activeColor} />
                </div>
              </OverlayTrigger>
            ) : (
              <>
                <Icon size={20} color={activeColor} />
                <span className={`${styles['link']}`}>{text}</span>
              </>
            )}
          </NavLink>
        );
      })}
      <div className={styles['coming-soon']}>Coming Soon!</div>
      {ComingSoonContentRoutes.map(({ text, icon }, index) => {
        const Icon = icon;
        return (
          <NavItem className={`${styles['wrapper']} ${styles['inactive']}`} key={`inactive-nav-${index}`}>
            {isSideBarCollapsed ? (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 250, hide: 400 }}
                defaultShow={false}
                overlay={<div className={styles['tooltip']}>{text}</div>}
                placement={'right'}
                flip={false}
                onHide={undefined}
                onToggle={undefined}
                popperConfig={undefined}
                show={undefined}
                target={undefined}
                data-testid={'tooltip'}
              >
                <div>
                  <Icon color={'#808080'} size={20} />
                </div>
              </OverlayTrigger>
            ) : (
              <>
                <Icon color={'#808080'} size={20} />
                <span>{text}</span>
              </>
            )}
          </NavItem>
        );
      })}
      <NavItem
        className={`${styles['wrapper']} ${styles['collapse-nav']} nav-item`}
        onClick={() => {
          setIsSideBarCollapsed(!isSideBarCollapsed);
        }}
        data-testid="collapse-button"
      >
        <img
          alt="collapseIcon"
          src={arrowLeftIcon}
          className={`${styles['icon']} ${!isSideBarCollapsed || styles['rotate']}`}
        />
        <span className={`${styles['link']}`}>Collapse Menu</span>
      </NavItem>

      <NavItem className={`${styles['wrapper']} ${styles['new-feature']}`}>
        <NewFeature />
      </NavItem>
    </Navbar>
  );
}

export default SideNavBar;
