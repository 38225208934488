import styles from './brand-control.module.scss';

/* eslint-disable-next-line */
export interface BrandControlData {
  title: string;
  details: string;
  type: 'Channel' | 'Registry' | 'Merchant' | 'Volatility';
}

export interface BrandControlProps {
  data: BrandControlData[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function BrandControl({ data }: BrandControlProps) {
  return (
    <div className={styles['container']}>
      {/*{data.map(({ title, details, type }) => {*/}
      {/*  return (*/}
      {/*    <Col key={type} md={6}>*/}
      {/*      <div className={styles['card']}>*/}
      {/*        <span className={styles['title']}>{title}</span>*/}
      {/*        <BrandControlCard details={details} type={type} />*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*  );*/}
      {/*})}*/}

      <span className={styles['coming-soon']}>Coming Soon!</span>
    </div>
  );
}

export default BrandControl;
