import { atomFamily, selectorFamily } from 'recoil';
import { ReviewsSnapshotInterface } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { ReviewsSnapshotQuery, ReviewsSnapshotForAsinResult } from './queries';
import { logger } from '../../logging';
import { activePeriodState } from './period';
import { companyIdState } from './company';

export type ReviewsSnapshotQueryParams = {
  endDate: string;
  brand: string;
  asin: string;
};

export const reviewsSnapshotState = atomFamily({
  key: 'asinReviewsSnapshot',
  default: selectorFamily<ReviewsSnapshotInterface | undefined, ReviewsSnapshotQueryParams>({
    key: 'ReviewsSnapshotQuery',
    get:
      ({ endDate, brand, asin }) =>
      ({ get }) => {
        const periodState = get(activePeriodState);
        const companyId = get(companyIdState);
        const period = periodState === 'QUAD' ? 'month' : 'week';
        return querySdn<ReviewsSnapshotForAsinResult>({
          query: ReviewsSnapshotQuery,
          variables: {
            endDate,
            brand,
            asin,
            period,
            companyId,
          },
        })
          .then((res) => {
            return res.data.dsaReviewsSnapshotForAsin;
          })
          .catch((e) => {
            logger.error(e);
            return undefined;
          });
      },
  }),
});
