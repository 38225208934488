import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { Login, LoginCallback, Logout, RouteWithAuthorization } from '@spins-gcp/auth0-react';
import { PageRoutes } from './pageRoutes';
import { ScrollToTop } from '../scrollToTop';

/* eslint-disable-next-line */
export interface DashboardProps {}

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/loginCallback" element={<LoginCallback />} />
      <Route path="/logout" element={<Logout />} />
      {PageRoutes.map(({ path, pageName, Component, Layout }) => (
        <Route
          key={path}
          path={path}
          element={
            <RouteWithAuthorization>
              <Layout path={path} pageName={pageName}>
                <ScrollToTop>
                  <Component />
                </ScrollToTop>
              </Layout>
            </RouteWithAuthorization>
          }
        />
      ))}
    </ReactRouterRoutes>
  );
}
