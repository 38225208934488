import styles from './review-summary.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

type Review = {
  label: string;
  percentage: number;
  color: string;
};
/* eslint-disable-next-line */
export interface ReviewSummaryProps {
  data: Review[];
}

export function ReviewSummary({ data }: ReviewSummaryProps) {
  return (
    <>
      {data.map((review) => (
        <div
          key={`review-summary-${review.label}`}
          className={styles['container']}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: '#E1E1E1' }} /> &nbsp;
          <span>{review.label} </span> &nbsp;
          <span>
            <svg version="1.1" width="100%" height="10" data-testid="bar">
              <rect width="100%" height="100%" fill="#E9E9E9" rx="2"></rect>
              <rect
                width={`${review.percentage}%`}
                height="100%"
                fill={review.color}
                rx="2"
                data-testid="colored-bar"
              ></rect>
            </svg>
          </span>
          <span style={{ width: '2em' }}>&nbsp;{review.percentage}%</span>
        </div>
      ))}
    </>
  );
}

export default ReviewSummary;
