import styles from './loading-spinner.module.scss';
import { GridLoader } from 'react-spinners';
import React from 'react';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {
  size?: number;
}

export function LoadingSpinner({ size = 15 }: LoadingSpinnerProps) {
  return (
    <div className={styles['container']}>
      <GridLoader size={size} color="#264653" />
    </div>
  );
}
