import styles from './content-score.module.scss';
import React from 'react';

/* eslint-disable-next-line */
export type ScoreDetail = {
  label: string;
  score: number;
  industryAvg: number;
  competitorAvg: number;
  grade: string;
};

export interface ContentScoreProps {
  score: number | undefined;
  details: ScoreDetail[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ContentScore({ score, details }: ContentScoreProps) {
  return (
    <div className={styles['container']}>
      {/*<Row>*/}
      {/*  <Col md={4}>*/}
      {/*    <div className={styles['meter-container']}>*/}
      {/*      <ContentScoreMeter score={score} width={250} height={160} />*/}
      {/*      <p className={styles['summary']}>*/}
      {/*        Your content score is{' '}*/}
      {/*        <span className={styles['good']}> Good! </span>*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*  <Col md={8} className="d-flex">*/}
      {/*    <Row className="row-cols-2 flex-grow-1">*/}
      {/*      {details.map((detail, index) => (*/}
      {/*        <ContentScoreDetails*/}
      {/*          detail={detail}*/}
      {/*          key={`content-score-detail-${index}`}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </Row>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<div className={styles['info']}>*/}
      {/*  <p>How is this score calculated? </p>*/}
      {/*  <p>*/}
      {/*    Scores how your product listing content stacks up against best*/}
      {/*    practices (on a weighted basis) across five critical page elements :*/}
      {/*    Product Title, Images, , Bullet Points, Product Description and EBC/A+*/}
      {/*    Content.*/}
      {/*  </p>*/}
      {/*</div>*/}
      <span className={styles['coming-soon']}>Coming Soon!</span>
    </div>
  );
}

export default ContentScore;
