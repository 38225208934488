import jwtDecode from 'jwt-decode';
import { getStorageItem } from './windowLocalStorageHelper';
import { AuthInfo } from '@dsa-ui/dsa-shared';

export const getAccessToken = () => getStorageItem('access_token');

interface SpinsJWT {
  'https://aot/email': string;
  'https://aot/userSecurityFields'?: { auth: AuthInfo };
}

const getJwt = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return undefined;
  }
  return jwtDecode<SpinsJWT>(accessToken);
};

export const getAuthTokenInfo = (): AuthInfo | undefined => {
  const jwt = getJwt();
  if (!jwt) {
    return undefined;
  }
  return jwt['https://aot/userSecurityFields']?.auth;
};
