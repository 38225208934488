import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import HeaderFilter from '../../components/header-filter/header-filter';
import React, { useState } from 'react';
import SalesSnapshot from '../../components/sales-snapshot/sales-snapshot';
import { HighlightCard } from '@spins-gcp/shared-components-react';
import SalesTrendsComparison from '../../components/sales-trends-comparison/sales-trends-comparison';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import ItemSalesTable from '../../components/item-sales-table/item-sales-table';
import styles from './item-sales.module.scss';
import { useRecoilValueLoadable } from 'recoil';
import { hasValue } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { asinSalesState } from '../../state/itemSales';

export function ItemSales() {
  const [term, setTerm] = useState<string>('');
  const filteredSales = useRecoilValueLoadable(asinSalesState);
  const shouldShowTable = hasValue(filteredSales);

  return (
    <Row className={'align-items-start'}>
      <Col className={'p-0'}>
        <div style={{ marginBottom: '20px', marginTop: '20px', height: '30px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className={styles['company-name']}>Item Sales</h3>

            <HeaderFilter />
          </div>
        </div>
        <Row>
          <Col md={9} s={9} xs={9}>
            <Row>
              <SalesSnapshot />
            </Row>
            <Row className={'p-2'}>
              <HighlightCard
                title={'Sales Trends Comparison'}
                externalStyles={{
                  padding: '0',
                  margin: '0',
                }}
              >
                <SalesTrendsComparison />
              </HighlightCard>
            </Row>
          </Col>
        </Row>
        <Row className={'p-0'}>
          <Col md={9} xs={9} s={9} className={'p-2'}>
            <HighlightCard
              title={'Sales by Product'}
              additionalTitle={
                <InputGroup size="sm" className={styles['search']}>
                  <InputGroup.Text className={styles['search-icon']}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    className={styles['search-text']}
                    type="search"
                    placeholder="Search"
                    data-testid={'search'}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                  />
                </InputGroup>
              }
            >
              {shouldShowTable && filteredSales.contents.length > 0 ? (
                <ItemSalesTable asins={filteredSales.contents} term={term}></ItemSalesTable>
              ) : (
                <span data-testid={'table-loading'}>
                  <LoadingSpinner />
                </span>
              )}
            </HighlightCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
