import SummaryCard from '../summary-card/summary-card';
import { formatNumber } from '@spins-gcp/shared-components-react';
import Reviews from '../reviews/reviews';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilValueLoadable } from 'recoil';
import { mainReviewsSnapshotState } from '../../state/reviewsPage';
import { hasValue, isLoading } from '../../utils/recoilUtils';
import { InlineLoadingSpinner } from '../inline-loading-spinner/inline-loading-spinner';
import { unitsDataTransform } from './unitsDataTransform';
import { salesSellerState } from '../../state/sales';

export function ReviewsSnapshot() {
  const unitsSold = useRecoilValueLoadable(salesSellerState);
  const mainReviewsSnapshotResult = useRecoilValueLoadable(mainReviewsSnapshotState);

  const unitsLoading = isLoading(unitsSold) || !hasValue(unitsSold);
  const reviewsLoading = isLoading(mainReviewsSnapshotResult) || !hasValue(mainReviewsSnapshotResult);

  const {
    reviewsCount = 0,
    averageRating = 0,
    averageRatingPercentChanged = 0,
    reviewsCountPercentChanged,
    reviewRatingSummaryData,
    ratingsHistoricalData,
    avgRatingHistoricalData,
  } = mainReviewsSnapshotResult.valueMaybe() || {};

  const { currentUnitSold, unitPercentChange, graphData } = unitsDataTransform(unitsSold.contents?.data);
  const units = graphData?.map((obj) => ({
    ...obj,
    title: 'Units',
  }));
  return (
    <Row className={'w-100 m-0 p-0'}>
      <Col md={9}>
        {reviewsLoading ? (
          <InlineLoadingSpinner />
        ) : (
          <Reviews
            reviewsCount={reviewsCount}
            averageRating={averageRating}
            averageRatingPercentChanged={averageRatingPercentChanged}
            reviewsCountPercentChanged={reviewsCountPercentChanged}
            reviewRatingSummaryData={reviewRatingSummaryData}
            ratingsHistoricalData={ratingsHistoricalData}
            avgRatingHistoricalData={avgRatingHistoricalData}
          />
        )}
      </Col>
      <Col md={3} className={'p-2 justify-content-end'}>
        <SummaryCard
          isLoading={unitsLoading}
          title={'Units Sold'}
          value={formatNumber(currentUnitSold, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
          percentChange={unitPercentChange ?? 0}
          graphData={units}
        />
      </Col>
    </Row>
  );
}

export default ReviewsSnapshot;
