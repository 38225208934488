import styles from './issue-tracker.module.scss';
import { useRecoilValueLoadable } from 'recoil';
import { outOfStockState } from '../../state/outOfStock';
import { hasValue } from '../../utils/recoilUtils';
import React from 'react';
import { suppressedBuyBoxState } from '../../state/suppressedBuyBox';

export function IssueTracker() {
  const outOfStock = useRecoilValueLoadable(outOfStockState);
  const suppressedBuyBox = useRecoilValueLoadable(suppressedBuyBoxState);

  return (
    <div className={styles['container']}>
      <div className={styles['issue']} data-testid={'oos-container'}>
        <span> OOS-Out of Stock</span>{' '}
        <span className={styles[`issue-icon`]}>{hasValue(outOfStock) ? outOfStock.getValue() : '...'}</span>
      </div>
      <div className={styles['issue']} data-testid={'sbb-container'}>
        <span> Suppressed Buy Box</span>{' '}
        <span className={styles[`issue-icon`]}>{hasValue(suppressedBuyBox) ? suppressedBuyBox.getValue() : '...'}</span>{' '}
      </div>
      <div className={styles['issue']} data-testid={'map-violations-container'}>
        <span className={styles['suppressed-buy-box']}> MAP Violations</span>{' '}
        <span className={styles['coming-soon']}>Coming Soon!</span>
      </div>
    </div>
  );
}

export default IssueTracker;
