import styles from './sales-trends-comparison.module.scss';
import Highcharts from 'highcharts';
import { HighchartsWrapper } from '../highcharts-wrapper/highcharts-wrapper';
import { formatNumberToKMB } from '../../utils/numberUtils';
import { computeXCoordinate, computeYCoordinate } from '../../utils/chartUtils';
import { useRecoilValueLoadable } from 'recoil';
import { salesSellerState } from '../../state/sales';
import { hasValue } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { activePeriodState } from '../../state/period';
import { BrandSummaryWeeklyAggregateItem } from '@dsa-ui/dsa-shared';
import * as R from 'ramda';

export function SalesTrendsComparison() {
  const activePeriod = useRecoilValueLoadable(activePeriodState);

  const totalSellerSales = useRecoilValueLoadable(salesSellerState);
  if (!hasValue(totalSellerSales)) {
    return (
      <div className={styles['container']}>
        <LoadingSpinner />
      </div>
    );
  }
  let janFlag = true;
  let decFlag = true;
  const trend: BrandSummaryWeeklyAggregateItem[] = R.sortWith<BrandSummaryWeeklyAggregateItem>([
    R.ascend(R.prop('week')),
  ])(totalSellerSales.contents.data);

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    tooltip: {
      borderWidth: 0,
      shared: true,
      shadow: false,
      useHTML: true,
      className: styles['tooltip'],

      positioner: /* istanbul ignore next */ function (labelWidth, __, { plotX, plotY }) {
        const x = computeXCoordinate(plotX, labelWidth, this.chart.chartWidth);

        const y = computeYCoordinate(plotY);
        return { x, y };
      },

      backgroundColor: 'transparent',
      formatter: /* istanbul ignore next */ function () {
        const content = this.points?.reduce(function (s, point) {
          return (
            s +
            `<div class="${styles['tooltip-label']}">` +
            `<div><span class="${styles['tooltip-icon']}" style="background-color: ${point.color};"> &nbsp;&nbsp;&nbsp; </span>` +
            '<span>' +
            point.series.name +
            '</span></div>' +
            '<span>' +
            formatNumberToKMB(point.y as number) +
            '</span>' +
            '</div>'
          );
        }, '<b>' + new Date(parseInt(this.x as string)).toString().substring(4, 10) + '</b>');

        return '<div>' + content + '</div>';
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        color: '#474747',
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
      },
      margin: 20,
    },
    yAxis: {
      title: {
        text: 'Sales',
        margin: 25,
      },
      labels: {
        // eslint-disable-next-line no-template-curly-in-string
        format: '${value:,.0f}', // dollar sign, thousands separator, no decimal places
      },
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: activePeriod.contents === 'WEEK' ? 'Week Ending' : 'Quads Ending',
        x: -40,
        y: 10,
        margin: 10,
      },
      crosshair: {
        dashStyle: 'Dash',
      },
      tickColor: 'transparent',
      tickPositions: trend.map((t: BrandSummaryWeeklyAggregateItem) => {
        return new Date(t.week).getTime();
      }),

      labels: {
        formatter: function (yMe) {
          const date = new Date(yMe.value as number);
          if ((date.getMonth() === 11 && decFlag) || (date.getMonth() === 0 && janFlag)) {
            if (date.getMonth() === 11) {
              decFlag = !decFlag;
            }

            if (date.getMonth() === 0) {
              janFlag = !janFlag;
            }

            return (
              '<div class="d-flex justify-content-start">' +
              '<span>' +
              Highcharts.dateFormat('%b %d', yMe.value as number).toUpperCase() +
              '</span>' +
              '<br />' +
              '<span>' +
              Highcharts.dateFormat('%Y', yMe.value as number).toUpperCase() +
              '</span>' +
              '</div>'
            );
          }

          return Highcharts.dateFormat('%b %d', yMe.value as number).toUpperCase();
        },
      },
    },
    time: {
      useUTC: true,
    },
    chart: {
      height: '300px',
      backgroundColor: 'transparent',
    },
    series: [
      {
        name: 'Seller',
        type: 'line',
        color: '#E8B227',
        data: trend.map((t: BrandSummaryWeeklyAggregateItem) => ({
          x: new Date(t.week).getTime() + new Date().getTimezoneOffset() * (60 * 1000),
          y: t.revenue,
        })),
      },
      {
        name: '3rd Party',
        type: 'line',
        color: '#799ABB',
        data: trend.map((t: BrandSummaryWeeklyAggregateItem) => {
          return {
            x: new Date(t.week).getTime() + new Date().getTimezoneOffset() * (60 * 1000),
            y: t.totalSales - t.revenue + t.thirdPartyRevenue,
          };
        }),
      },
      {
        name: 'Total',
        type: 'line',
        color: '#A0B980',
        data: trend.map((t: BrandSummaryWeeklyAggregateItem) => {
          return {
            x: new Date(t.week).getTime() + new Date().getTimezoneOffset() * (60 * 1000),
            y: t.totalSales + t.thirdPartyRevenue,
          };
        }),
      },
    ],

    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: '#FFFFFF',
          lineWidth: 2,
        },
      },
    },
    credits: { enabled: false },
  };

  return (
    <div className={styles['container']}>
      <HighchartsWrapper options={chartOptions} />
    </div>
  );
}

export default SalesTrendsComparison;
