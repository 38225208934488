import React from 'react';
import styles from './home.module.scss';
import { Col, Row } from 'react-bootstrap';
import { HighlightCard } from '@spins-gcp/shared-components-react';
import SalesSnapshot from '../../components/sales-snapshot/sales-snapshot';
import SalesTrendsComparison from '../../components/sales-trends-comparison/sales-trends-comparison';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import IssueTracker from '../../components/issue-tracker/issue-tracker';
import AttributeHighlights from '../../components/attribute-highlights/attribute-highlights';
import ContentScore from '../../components/content-score/content-score';
import ReviewHighlights from '../../components/review-highlights/review-highlights';
import BrandControl, { BrandControlData } from '../../components/brand-control/brand-control';
import HeaderFilter from '../../components/header-filter/header-filter';
import DashboardReviews from '../../components/dashboard-reviews/dashboard-reviews';
import { useRecoilValueLoadable } from 'recoil';
import { asinHighestLowestRatingState } from '../../state/asin';
import { hasValue } from '../../utils/recoilUtils';
import { InlineLoadingSpinner } from '../../components/inline-loading-spinner/inline-loading-spinner';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface HomeProps {}

export function Home() {
  const dummyData = {
    issues: [
      { issueTitle: 'OOS-Out of Stock', issueNumber: 12, issueLink: '/' },
      { issueTitle: 'Suppressed Buy Box', issueNumber: 16, issueLink: '/' },
      { issueTitle: 'MAP Violations', issueNumber: 8, issueLink: '/' },
    ],
    averageProductData: {
      positive: [8000, 6500, 9000, 7000, 5000, 5400],
      negative: [5000, 3000, 6000, 9000, 2500, 3000],
    },
    contentScoreData: {
      score: 82,
      contentScoreDetails: [
        {
          label: 'Product title',
          score: 68,
          industryAvg: -24,
          competitorAvg: -10,
          grade: 'D+',
        },
        {
          label: 'Bullets',
          score: 27,
          industryAvg: -2,
          competitorAvg: -10,
          grade: 'D-',
        },
        {
          label: 'Description',
          score: 87,
          industryAvg: 45,
          competitorAvg: 10,
          grade: 'B+',
        },

        {
          label: 'Images/Videos',
          score: 96,
          industryAvg: 20,
          competitorAvg: 12,
          grade: 'A+',
        },
      ],
    },
    brandControlData: [
      {
        title: 'Auth. Channel Share',
        details: '81%',
        type: 'Channel',
      },
      {
        title: 'Brand Registry Enrolled',
        details: 'All Set!',
        type: 'Registry',
      },
      {
        title: 'Merchant Market Share',
        details: '31%',
        type: 'Merchant',
      },
      {
        title: 'Price Volatility',
        details: '5%',
        type: 'Volatility',
      },
    ],
  };

  const lowestHighest = useRecoilValueLoadable(asinHighestLowestRatingState);

  return (
    <Row className={'align-items-start'}>
      <Col className={'p-0'}>
        <div style={{ marginBottom: '20px', marginTop: '20px', height: '30px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className={styles['company-name']}>Dashboard</h3>

            <HeaderFilter />
          </div>
        </div>

        <Row>
          <Col md={9} s={9} xs={9}>
            <Row>
              <SalesSnapshot />
            </Row>
            <Row className={'p-2'}>
              <HighlightCard
                title={'Sales Trends Comparison'}
                externalStyles={{
                  padding: '0',
                  margin: '0',
                }}
              >
                <SalesTrendsComparison />
              </HighlightCard>
            </Row>
          </Col>
          <Col md={3} s={3} xs={3} className={'p-2'}>
            <div className="d-flex flex-column" style={{ height: '103%' }}>
              <HighlightCard title={'Issue Tracker'} externalStyles={{ paddingBottom: '16px' }}>
                <IssueTracker></IssueTracker>
              </HighlightCard>
              <HighlightCard
                title="Attribute Highlights"
                externalStyles={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}
                childrenExternalStyles={{ flex: '1 1 auto', display: 'flex' }}
              >
                <AttributeHighlights />
              </HighlightCard>
            </div>
          </Col>
        </Row>
        <Row className="pdf-page-break-before">
          <Col md={9} s={9} xs={9} style={{ display: 'flex' }} className={'p-2'}>
            <HighlightCard
              title={'Reviews'}
              childrenExternalStyles={{
                paddingBottom: '0',
              }}
              externalStyles={{ flex: '1 1 auto', padding: '0', margin: '0' }}
              header={
                <Link to="/reviews" className={styles['link']}>
                  See all reviews &nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              }
            >
              <DashboardReviews />
            </HighlightCard>
          </Col>
          <Col md={3} s={3} xs={3} className={[styles['review-highlights'], 'p-2'].join(' ')}>
            <HighlightCard
              title="Review Highlights"
              childrenExternalStyles={{
                padding: '16px',
              }}
              externalStyles={{ paddingBottom: '16px', margin: '0' }}
            >
              {hasValue(lowestHighest) ? (
                <ReviewHighlights
                  lowestItem={lowestHighest.contents.lowestItem}
                  highestItem={lowestHighest.contents.highestItem}
                ></ReviewHighlights>
              ) : (
                <InlineLoadingSpinner />
              )}
            </HighlightCard>
          </Col>
        </Row>
        <Row>
          <Col md={4} s={4} xs={4} className={[styles['brand-control'], 'p-2'].join(' ')}>
            <HighlightCard
              title="Brand Control"
              externalStyles={{ flexGrow: 1 }}
              childrenExternalStyles={{
                height: 'calc(100% - 53px)',
              }}
            >
              <BrandControl data={dummyData.brandControlData as BrandControlData[]} />
            </HighlightCard>
          </Col>
          <Col className={[styles['content-card'], 'p-2', 'd-flex'].join(' ')} md={8} s={8} xs={8}>
            <HighlightCard
              externalStyles={{ flexGrow: 1 }}
              childrenExternalStyles={{ padding: '16px' }}
              title="Content"
              header={
                <Link to="/" className={`${styles['link']}`}>
                  Manage content &nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              }
            >
              <ContentScore
                score={dummyData.contentScoreData.score}
                details={dummyData.contentScoreData.contentScoreDetails}
              />
            </HighlightCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
