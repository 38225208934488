import { DateTime } from 'luxon';

export const getTime = () => new Date().getTime();

export const getMonthName = (monthNumber: number) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
    month: 'short',
  });
};

export const getDateStringFromISODate = (date: string) => {
  const dateTime = DateTime.fromISO(date);

  return dateTime.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' }).replace(',', '');
};
