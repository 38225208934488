import { atom, selector } from 'recoil';
import { getAuthTokenInfo } from '../utils/jwtUtils';
import { AuthInfo, DsaAppId, getUserSellerIds, SdnoAuthPayload } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { GetUserAuthPayload, GetUserAuthPayloadResult } from './queries';
import { logger } from '../../logging';

export const authTokenUserState = atom({
  key: 'AuthTokenUser',
  default: selector<AuthInfo | undefined>({
    key: 'AuthTokenUserQuery',
    get: () => {
      return getAuthTokenInfo();
    },
  }),
});

export const sdnoAuthPayload = atom({
  key: 'SdnoAuthPayload',
  default: selector<SdnoAuthPayload | undefined>({
    key: 'SdnoAuthPayloadQuery',
    get: () => {
      return querySdn<GetUserAuthPayloadResult>({
        query: GetUserAuthPayload,
      })
        .then((res) => res.data.sdnoAuthPayload)
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});

export const getUserCompanyId = atom({
  key: 'getUserCompanyId',
  default: selector<string | undefined>({
    key: 'userCompanyIdValue',
    get: ({ get }) => {
      const sdnoAuthPayloadValue = get(sdnoAuthPayload);
      if (!sdnoAuthPayloadValue) {
        return undefined;
      }

      return sdnoAuthPayloadValue.company.id;
    },
  }),
});

export const userSellerIdState = atom({
  key: 'getUserSellerId',
  default: selector<string | undefined>({
    key: 'userSellerIdValue',
    get: ({ get }) => {
      const authTokenUserStateValue = get(authTokenUserState);
      if (!authTokenUserStateValue) {
        return undefined;
      }

      const sdnoAuthPayloadValue = get(sdnoAuthPayload);
      const hasAccess = (sdnoAuthPayloadValue?.user?.apps?.val || []).find((i) => i.id === DsaAppId);

      // TODO: This is temporary until SDN-Office gets all spins users proper access
      if (!hasAccess) {
        const isSpinsUser = authTokenUserStateValue.user.email.endsWith('@spins.com');

        if (isSpinsUser) {
          logger.silly('Granting access as SPINS user, but SDN-Office stills says you do not');
        } else {
          return undefined;
        }
      }

      const sellerIds = getUserSellerIds(authTokenUserStateValue.user.email);

      return sellerIds.length > 0 ? sellerIds[0].sellerId : undefined;
    },
  }),
});

export const userBrandState = atom({
  key: 'getUserBrand',
  default: selector<string | undefined>({
    key: 'userBrandValue',
    get: ({ get }) => {
      const authTokenUserStateValue = get(authTokenUserState);
      if (!authTokenUserStateValue) {
        return undefined;
      }

      const sdnoAuthPayloadValue = get(sdnoAuthPayload);
      const hasAccess = (sdnoAuthPayloadValue?.user?.apps?.val || []).find((i) => i.id === DsaAppId);

      // TODO: This is temporary until SDN-Office gets all spins users proper access
      if (!hasAccess) {
        const isSpinsUser = authTokenUserStateValue.user.email.endsWith('@spins.com');

        if (isSpinsUser) {
          logger.silly('Granting access as SPINS user, but SDN-Office stills says you do not');
        } else {
          return undefined;
        }
      }

      const brand = getUserSellerIds(authTokenUserStateValue.user.email);

      return brand.length > 0 ? brand[0].brands[0] : undefined;
    },
  }),
});

export const reflowHighCharts = atom({
  key: 'reflowHighCharts',
  default: 0,
});

export const isSideNavBarCollapsed = atom({
  key: 'isSideNavBarCollapsed',
  default: false,
});
