import { atom, selector } from 'recoil';
import { SalesItem } from '@dsa-ui/dsa-shared';
import { userBrandState } from './user';
import { logger } from '../../logging';
import { querySdn } from '../utils/sdnClient';
import { DsaAsinSales, DsaAsinSalesResult } from './queries';
import { activePeriodState, endDateState } from './period';
import { companyIdState } from './company';

export const asinSalesState = atom({
  key: 'asinSales',
  default: selector<SalesItem[] | undefined>({
    key: 'GetAsinSales',
    get: async ({ get }) => {
      const period = get(activePeriodState);
      const endDate = get(endDateState);
      const brand = get(userBrandState);
      const companyId = get(companyIdState);
      if (!brand) {
        return undefined;
      }

      return querySdn<DsaAsinSalesResult>({
        query: DsaAsinSales,
        variables: { brand, endDate, period, companyId },
      })
        .then((res) => res.data.dsaAsinSales)
        .catch((error) => {
          logger.error(error);
          return undefined;
        });
    },
  }),
});
