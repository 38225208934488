import styles from './review-details-table.module.scss';
import { Table } from 'react-bootstrap';
import React, { useEffect } from 'react';

import { useRecoilValueLoadable } from 'recoil';
import { ReviewRequest, reviewsByAsinState } from '../../state/reviewsPage';
import { hasValue } from '../../utils/recoilUtils';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import TableFooter from '../rating-table/table-footer/table-footer';
import { filterArrayByKeys } from '../../utils/helperUtils';
import { recordKeyObject, useSortColumns } from '../../hooks/useSortColumns';
import { useTable } from '../../hooks/useTable';
import { ReviewDetailsRow } from './review-details-row/review-details-row';
import { ReviewWithNotes } from '@dsa-ui/dsa-shared';

export interface ReviewDetailsTableProps {
  reviewRequest: ReviewRequest;
  term: string;
  starFilter: { lowerBound?: number; upperBound?: number } | undefined;
}

export type Headers = {
  name: string;
  field?: string | recordKeyObject;
  type?: ColumnType;
  width: number;
};

export enum ColumnType {
  STRING,
  BOOLEAN,
  NUMBER,
  LINK,
  OBJECT,
}

export function ReviewDetailsTable({ reviewRequest, term, starFilter }: ReviewDetailsTableProps) {
  const reviewsData = useRecoilValueLoadable(reviewsByAsinState(reviewRequest));

  const filteredArray = filterArrayByKeys((reviewsData.valueMaybe()?.reviews ?? []) as ReviewWithNotes[], term, [
    'review',
    'review_title',
    'note',
  ]).filter((item) => {
    if (starFilter) {
      const { upperBound, lowerBound } = starFilter;
      const star = item.stars || 0;
      if (!upperBound && lowerBound) return star >= lowerBound;
      if (!lowerBound && upperBound) return star <= upperBound;
      return upperBound && lowerBound && star >= lowerBound && star <= upperBound;
    }
    return true;
  });

  const { items, requestSort, CaretIcon } = useSortColumns<ReviewWithNotes>(
    filteredArray,
    {
      recordKey: 'review_date',
      direction: 'descending',
    },
    ColumnType.STRING
  );
  const { page, setRowsPerPage, nextPage, prevPage, paginated, maxPage, setPage } = useTable<ReviewWithNotes>(items);

  useEffect(() => {
    if (!!term || !!starFilter) {
      setPage(1);
    }
  }, [term, starFilter, setPage]);
  const headers: Headers[] = [
    {
      name: 'Review Date',
      field: 'review_date',
      width: 110,
      type: ColumnType.NUMBER,
    },
    {
      name: 'Stars',
      field: 'stars',
      width: 92,
      type: ColumnType.NUMBER,
    },
    {
      name: 'Marked Helpful',
      field: 'found_helpful',
      width: 80,
      type: ColumnType.NUMBER,
    },
    {
      name: 'Review Title',
      width: 150,
    },
    {
      name: 'Review',
      width: 380,
    },
    {
      name: 'Image/Video',
      width: 120,
      field: 'image_links',
      type: ColumnType.LINK,
    },
    {
      name: 'Analyzed',
      width: 94,
      field: { objKey: 'note', propKey: 'is_analyzed' },
      type: ColumnType.BOOLEAN,
    },
    {
      name: 'Notes',
      width: 292,
      field: { objKey: 'note', propKey: 'note' },
      type: ColumnType.OBJECT,
    },
  ];

  return !hasValue(reviewsData) || !reviewsData ? (
    <LoadingSpinner />
  ) : (
    <div className={styles['review-details-table']}>
      <Table className={`${styles['table']} table-bordered`}>
        <thead>
          <tr className={styles['header']}>
            {headers.map((h) => {
              return (
                <th
                  className={styles['left']}
                  style={{ width: h.width }}
                  key={h.name}
                  data-testid={`header-${h.name}`}
                  onClick={() => h.field && h.type && requestSort(h.field, h.type)}
                >
                  {h.name} {h.field && <CaretIcon recordKey={h.field} />}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {paginated.length === 0 ? (
            <tr>
              <td colSpan={8}>No data found</td>
            </tr>
          ) : (
            paginated.map((item: ReviewWithNotes) => <ReviewDetailsRow item={item} key={item.review_id} term={term} />)
          )}
        </tbody>
      </Table>
      <div className={styles['footer']}>
        <TableFooter
          maxPage={maxPage}
          nextPage={nextPage}
          previousPage={prevPage}
          currentPage={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

export default ReviewDetailsTable;
