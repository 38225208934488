import * as R from 'ramda';

const MediNaturaSellerId = 'A9YN5CKB2CICI';

/*
Unilever Seller Id's
AQT75XPTIDBD9 - Onnit
A38WH8TXFNW7VD - liquid IV

Vendor Id's
4084610 has multiple brands, including Olly
1955820 - smartypants

The first 2 are sellerid's, the last 2 are vendor
*/

export interface SellerMapping {
  sellerId: string;
  brands: string[];
}

export interface UserSellerMapping {
  email: string;
  allowedSellers: SellerMapping[];
}

const mediNatura: SellerMapping = {
  sellerId: MediNaturaSellerId,
  brands: ['MediNatura'],
};

const allSellers = [mediNatura];

// ! This is a temporary mapping until we are able to get this information from SDNO or some place else
const userSellerBrandMapping: UserSellerMapping[] = [
  {
    email: 'cclive@medinatura.com',
    allowedSellers: [mediNatura],
  },
  {
    email: 'msmith@medinatura.com',
    allowedSellers: [mediNatura],
  },
  {
    email: 'cyoungblood@medinatura.com',
    allowedSellers: [mediNatura],
  },
];
export const getUserSellerIds = (email: string): SellerMapping[] => {
  if (email.endsWith('@spins.com')) {
    return allSellers;
  }

  return R.find<UserSellerMapping>(R.propEq(email, 'email'))(userSellerBrandMapping)?.allowedSellers || [];
};

export const getUserSellerIdBrands = (email: string, sellerId: string): string[] => {
  const userSellerIds = getUserSellerIds(email);
  return R.pipe(R.find(R.propEq(sellerId, 'sellerId')), R.propOr([], 'brands'))(userSellerIds) as string[];
};
