import { atom, selector } from 'recoil';
import { endDateState } from './period';
import { querySdn } from '../utils/sdnClient';
import { GetOutOfStockSellerQuery, GetOutOfStockSellerResult } from './queries';
import { OutOfStockWeek } from '@dsa-ui/dsa-shared';
import { logger } from '../../logging';
import { userSellerIdState } from './user';

export const outOfStockState = atom({
  key: 'outOfStock',
  default: selector<number>({
    key: 'outOfStockNumber',
    get: ({ get }) => {
      const sellerId = get(userSellerIdState);
      const endPeriod = get(endDateState);

      if (!sellerId) {
        logger.info('Seller id is undefined');
        return 0;
      }

      return querySdn<GetOutOfStockSellerResult>({
        query: GetOutOfStockSellerQuery,
        variables: { sellerId: sellerId, week: endPeriod },
      })
        .then((res) =>
          res.data.getSellerOutOfStock
            .reduce((previousValue, currentValue) => previousValue.concat(currentValue.weeks), [] as OutOfStockWeek[])
            .reduce((prev, curr) => prev + curr.outOfStock, 0)
        )
        .catch((e) => {
          logger.error(e);
          return 0;
        });
    },
  }),
});
