import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  AsinItem,
  AverageData,
  MainRatingDistributionInterface,
  MainReviewsSnapshotInterface,
  RatingSummaryData,
  ReviewByAsinResponse,
  XYDataPoint,
} from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import {
  AsinsReviewSummaryItem,
  AsinsReviewSummaryResult,
  GetAsins,
  GetAsinsResult,
  GetAsinsReviewSummary,
  GetReviewsByAsin,
  MainRatingDistributionQuery,
  MainReviewsSnapshotQuery,
  RatingDistributionResult,
  ReviewByAsinResult,
  ReviewsSnapshotResult,
} from './queries';
import { logger } from '../../logging';
import { activePeriodState, endDateState } from './period';
import { userBrandState } from './user';
import { DateTime } from 'luxon';
import { companyIdState } from './company';

export const mainReviewsSnapshotState = atom({
  key: 'mainReviewsSnapshot',
  default: selector<MainReviewsSnapshotInterface | undefined>({
    key: 'MainReviewsSnapshotQuery',
    get: ({ get }) => {
      const periodState = get(activePeriodState);
      const brand = get(userBrandState);
      const companyId = get(companyIdState);
      const period = periodState === 'QUAD' ? 'month' : 'week';
      const endDate = get(endDateState);
      return querySdn<ReviewsSnapshotResult>({
        query: MainReviewsSnapshotQuery,
        variables: {
          endDate,
          brand,
          period,
          companyId,
        },
      })
        .then((res) => {
          const reviewsSnapshotResult = res.data.dsaReviewsSnapshot;
          const ratingSummaryData: RatingSummaryData = {
            star1: 0,
            star2: 0,
            star3: 0,
            star4: 0,
            star5: 0,
          };
          Object.keys(reviewsSnapshotResult?.ratings).forEach(
            (key: string) =>
              (ratingSummaryData[key as keyof RatingSummaryData] =
                reviewsSnapshotResult.totalRatings !== 0
                  ? (100 * reviewsSnapshotResult.ratings[key as keyof RatingSummaryData]) /
                    reviewsSnapshotResult.totalRatings
                  : 0)
          );
          const ratingsHistoricalData: XYDataPoint[] = [];
          const avgRatingHistoricalData: XYDataPoint[] = [];
          reviewsSnapshotResult.ratingsHistory?.forEach(
            (history: { period: number; totalRating: number; averageRating: number; week: string }, index: number) => {
              const week = DateTime.fromJSDate(new Date(history.week)).toISODate();
              ratingsHistoricalData.push({ x: index, y: history.totalRating, week });
              avgRatingHistoricalData.push({ x: index, y: history.averageRating, week });
            }
          );
          return {
            ratingsHistoricalData,
            avgRatingHistoricalData,
            reviewsCount: reviewsSnapshotResult.totalRatings,
            averageRating: reviewsSnapshotResult.averageRatings,
            reviewsCountPercentChanged:
              reviewsSnapshotResult.previousTotal && reviewsSnapshotResult.previousTotal !== 0
                ? (100 * (reviewsSnapshotResult.totalRatings - reviewsSnapshotResult.previousTotal)) /
                  reviewsSnapshotResult.previousTotal
                : 100,
            averageRatingPercentChanged:
              reviewsSnapshotResult.previousAverage && reviewsSnapshotResult.previousAverage !== 0
                ? (100 * (reviewsSnapshotResult.averageRatings - reviewsSnapshotResult.previousAverage)) /
                  reviewsSnapshotResult.previousAverage
                : 100,
            reviewRatingSummaryData: ratingSummaryData,
          };
        })
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});

export const mainRatingDistributionState = atom({
  key: 'ratingDistribution',
  default: selector<MainRatingDistributionInterface | undefined>({
    key: 'MainRatingDistributionQuery',
    get: ({ get }) => {
      const brand = get(userBrandState);
      const period = get(activePeriodState);
      const endDate = get(endDateState);
      const companyId = get(companyIdState);
      return querySdn<RatingDistributionResult>({
        query: MainRatingDistributionQuery,
        variables: { brand, period, endDate, companyId },
      })
        .then((res) => {
          const ratingDistributionResult = res.data.dsaRatingDistribution;
          const data: AverageData = { positive: [], negative: [] };
          const xData: number[] = [];
          let totalNegative = 0;
          let totalPositive = 0;
          for (const item of ratingDistributionResult) {
            xData.push(new Date(item.periodEnd).getTime());
            data.positive.push(item.positive);
            data.negative.push(item.negative);
            if (item.allNegative && item.allPositive) {
              totalNegative = item.allNegative;
              totalPositive = item.allPositive;
            }
          }
          return {
            data,
            xData,
            totalNegative,
            totalPositive,
          };
        })
        .catch((e) => {
          logger.error(e);
          return undefined;
        });
    },
  }),
});

export type ReviewRequest = {
  asin: string;
};

export const reviewsByAsinState = atomFamily({
  key: 'reviewsByAsin',
  default: selectorFamily<ReviewByAsinResponse, ReviewRequest>({
    key: 'ReviewsByAsinQuery',
    get:
      ({ asin }) =>
      ({ get }) => {
        if (!asin) {
          return { total: 0, reviews: [] };
        }
        const brand = get(userBrandState);
        const companyId = get(companyIdState);
        return querySdn<ReviewByAsinResult>({
          query: GetReviewsByAsin,
          variables: {
            asin,
            brand,
            companyId,
          },
        })
          .then((res) => {
            return res.data.dsaGetReviewsByAsin;
          })
          .catch((e) => {
            logger.error(e);
            return { total: 0, reviews: [] };
          });
      },
  }),
});

export const asinsState = atom({
  key: 'asins',
  default: selector<AsinItem[]>({
    key: 'GetAsinsQuery',
    get: ({ get }) => {
      const brand = get(userBrandState);
      const companyId = get(companyIdState);
      if (!brand) {
        logger.info('Seller id is undefined');
        return [];
      }

      return querySdn<GetAsinsResult>({
        query: GetAsins,
        variables: {
          brand,
          companyId,
        },
      })
        .then((res) => {
          return res.data.dsaGetAllAsins;
        })
        .catch((e) => {
          logger.error(e);
          return [];
        });
    },
  }),
});

export const filteredAsinsState = atom({
  key: 'filteredAsins',
  default: selector<AsinsReviewSummaryItem[] | undefined>({
    key: 'GetFilteredAsins',
    get: ({ get }) => {
      const brand = get(userBrandState);
      const endDate = get(endDateState);
      const companyId = get(companyIdState);
      if (!brand) return undefined;

      return querySdn<AsinsReviewSummaryResult>({
        query: GetAsinsReviewSummary,
        variables: {
          brand,
          endDate,
          companyId,
        },
      })
        .then((res) => res.data.result)
        .catch((error) => {
          logger.error(error);
          return [];
        });
    },
  }),
});
