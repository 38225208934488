import styles from './selectable-pill.module.scss';
import { CheckCircle } from 'react-feather';
import React from 'react';

/* eslint-disable-next-line */
export interface SelectablePillProps<T> {
  label: string;
  selected?: boolean;
  setFilter?: React.Dispatch<React.SetStateAction<T>>;
}

export function SelectablePill<T>({ label, selected = false, setFilter }: SelectablePillProps<T>) {
  return (
    <span
      className={styles['pill'] + (selected ? ` ${styles['selected']}` : '')}
      onClick={() => setFilter && setFilter(label as T)}
    >
      {selected && <CheckCircle className={styles['check-icon']} size={16} data-testid={'check'} />}
      {label}
    </span>
  );
}

export default SelectablePill;
