import { useRecoilValueLoadable } from 'recoil';
import { reviewsSnapshotState } from '../../../state/reviewsSnapshot';
import { hasValue, isLoading } from '../../../utils/recoilUtils';
import React from 'react';
import { RatingSummaryData } from '@dsa-ui/dsa-shared';
import { asinUnitsSoldState } from '../../../state/unitsSold';
import { Col, Row } from 'react-bootstrap';
import { InlineLoadingSpinner } from '../../inline-loading-spinner/inline-loading-spinner';
import Reviews from '../../reviews/reviews';
import SummaryCard from '../../summary-card/summary-card';
import { formatNumber } from '@spins-gcp/shared-components-react';
import { unitsDataTransform } from '../unitsDataTransform';
import { DateTime } from 'luxon';
import { computePercentChange } from '../../../utils/mathUtils';
import { endDateState } from '../../../state/period';

export interface AsinReviewsSnapshotProps {
  brand: string;
  asin: string;
}

export function AsinReviewsSnapshot({ brand, asin }: AsinReviewsSnapshotProps) {
  const endDate = useRecoilValueLoadable(endDateState);

  const reviewsSnapshotResult = useRecoilValueLoadable(
    reviewsSnapshotState({
      endDate: endDate.valueMaybe() ?? '',
      brand,
      asin,
    })
  );
  const unitsSold = useRecoilValueLoadable(asinUnitsSoldState({ asin }));

  const unitsLoading = isLoading(unitsSold) || !hasValue(unitsSold);
  const reviewsLoading = isLoading(reviewsSnapshotResult) || !hasValue(reviewsSnapshotResult);

  const reviewRatingSummaryData: RatingSummaryData = {
    star1: 0,
    star2: 0,
    star3: 0,
    star4: 0,
    star5: 0,
  };

  if (!reviewsLoading) {
    Object.keys(reviewsSnapshotResult.contents?.ratings).forEach(
      (key: string) =>
        (reviewRatingSummaryData[key as keyof RatingSummaryData] =
          reviewsSnapshotResult.contents.totalRatings !== 0
            ? (100 * reviewsSnapshotResult.contents.ratings[key]) / reviewsSnapshotResult.contents.totalRatings
            : 0)
    );
  }

  const ratingsHistoricalData: { x: number; y: number; week: string }[] = [];
  const avgRatingHistoricalData: { x: number; y: number; week: string }[] = [];
  reviewsSnapshotResult?.contents?.ratingsHistory?.forEach(
    (history: { period: number; totalRating: number; averageRating: number; week: string }, index: number) => {
      const week = DateTime.fromJSDate(new Date(history.week)).toISODate();

      ratingsHistoricalData.push({ x: index, y: history.totalRating, week });
      avgRatingHistoricalData.push({ x: index, y: history.averageRating, week });
    }
  );

  const averageRatingPercentChanged: number | undefined = !reviewsLoading
    ? computePercentChange(
        reviewsSnapshotResult.contents.previousAverage,
        reviewsSnapshotResult.contents.averageRatings
      )
    : undefined;

  const reviewsCountPercentChanged: number | undefined = !reviewsLoading
    ? computePercentChange(reviewsSnapshotResult.contents.previousTotal, reviewsSnapshotResult.contents.totalRatings)
    : undefined;

  // TODO: Mock this in test
  const { currentUnitSold, unitPercentChange, graphData } = unitsDataTransform(unitsSold.contents);

  return (
    <Row className={'w-100 m-0 p-0'}>
      <Col md={9}>
        {reviewsLoading ? (
          <InlineLoadingSpinner />
        ) : (
          <Reviews
            reviewsCount={reviewsSnapshotResult.contents.totalRatings}
            averageRating={reviewsSnapshotResult.contents.averageRatings}
            averageRatingPercentChanged={averageRatingPercentChanged}
            reviewsCountPercentChanged={reviewsCountPercentChanged}
            reviewRatingSummaryData={reviewRatingSummaryData}
            ratingsHistoricalData={ratingsHistoricalData}
            avgRatingHistoricalData={avgRatingHistoricalData}
          />
        )}
      </Col>
      <Col md={3} className={'p-2 justify-content-end'}>
        <SummaryCard
          isLoading={unitsLoading}
          title={'Units Sold'}
          value={formatNumber(currentUnitSold, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
          percentChange={unitPercentChange ?? 0}
          graphData={graphData}
        />
      </Col>
    </Row>
  );
}
