import { atomFamily, selectorFamily } from 'recoil';
import { UnitsSold } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { activePeriodState, endDateState } from './period';
import { GetUnitSoldQuery, GetUnitSoldQueryResult } from './queries';
import { companyIdState } from './company';

export const asinUnitsSoldState = atomFamily({
  key: 'asinUnitsSoldState',
  default: selectorFamily<Promise<UnitsSold[] | undefined>, AsinUnitsSoldParams>({
    key: 'asinUnitsSoldSelector',
    get:
      ({ asin }) =>
      async ({ get }) => {
        const endDate = get(endDateState);
        const period = get(activePeriodState);
        const companyId = get(companyIdState);
        return querySdn<GetUnitSoldQueryResult>({
          query: GetUnitSoldQuery,
          variables: {
            asin,
            period,
            endDate,
            companyId,
          },
        })
          .then((res) => {
            return res.data.dsaAsinUnitsSold;
          })
          .catch(() => undefined);
      },
  }),
});

export type AsinUnitsSoldParams = { asin: string };
