import { atomFamily, selectorFamily } from 'recoil';
import { IssueMentioned } from '@dsa-ui/dsa-shared';
import { querySdn } from '../utils/sdnClient';
import { IssuesMentionedQuery, IssuesMentionedResult } from './queries';
import { logger } from '../../logging';
import { companyIdState } from './company';

export type IssuesMentionedQueryParams = {
  asin: string;
};

export const issuesMentionedState = atomFamily({
  key: 'issuesMentioned',
  default: selectorFamily<IssueMentioned[] | undefined, IssuesMentionedQueryParams>({
    key: 'IssuesMentionedQuery',
    get:
      ({ asin }) =>
      ({ get }) => {
        const companyId = get(companyIdState);
        return querySdn<IssuesMentionedResult>({
          query: IssuesMentionedQuery,
          variables: {
            asin,
            companyId,
          },
        })
          .then((res) => {
            return res.data.dsaIssuesMentioned;
          })
          .catch((e) => {
            logger.error(e);
            return undefined;
          });
      },
  }),
});
