import { Home } from '../pages/home/home';
import { PropsWithChildren } from 'react';
import { FullPage } from '../layouts/full-page/full-page';
import { Grid, FileText, Activity, BarChart2, Radio, Layout, Settings, Icon } from 'react-feather';
import { ReviewsPage } from '../pages/reviewspage/reviewspage';
import ReviewDetails from '../pages/review-details/review-details';
import { ItemSales } from '../pages/item-sales/item-sales';

export type ReactFunctionalComponentNoProps = () => JSX.Element;
export type ReactFunctionalComponentChildrenProps<P> = (props: PropsWithChildren<P>) => JSX.Element;

export interface PageConfig {
  path: string;
  pageName: string;
}

export interface ContentRoute {
  pathname: string;
  icon: Icon;
  text: string;
}

export interface CustomRoute extends PageConfig {
  Component: ReactFunctionalComponentNoProps;
  Layout: ReactFunctionalComponentChildrenProps<PageConfig>;
}

export const PagePaths = {
  Dashboard: '/',
  Merchant: '/merchant',
  ItemSales: '/items',
  Issues: '/issues',
  Reviews: '/reviews',
  ReviewDetails: '/reviews/:asin',
  Content: '/content',
  Advertising: '/advertising',
  Settings: '/settings',
  Reporting: '/reporting',
};

export const PageRoutes: CustomRoute[] = [
  {
    path: PagePaths.Dashboard,
    pageName: 'Digital Shelf Analytics',
    Component: Home,
    Layout: FullPage,
  },
  {
    path: PagePaths.Reviews,
    pageName: 'Digital Shelf Analytics',
    Component: ReviewsPage,
    Layout: FullPage,
  },
  {
    path: PagePaths.ReviewDetails,
    pageName: 'Digital Shelf Analytics - Review Details',
    Component: ReviewDetails,
    Layout: FullPage,
  },
  {
    path: PagePaths.ItemSales,
    pageName: 'Digital Shelf Analytics',
    Component: ItemSales,
    Layout: FullPage,
  },
];
export const ContentRoutes: ContentRoute[] = [
  {
    text: 'Dashboard',
    icon: Grid,
    pathname: '/',
  },
  {
    text: 'Reviews',
    icon: FileText,
    pathname: '/reviews',
  },
  {
    text: 'Item Sales',
    icon: Activity,
    pathname: '/items',
  },
  {
    text: '3rd Party Reporting',
    icon: BarChart2,
    pathname: '/reporting',
  },
  {
    text: 'Issue Tracker',
    icon: Radio,
    pathname: '/issues',
  },
  {
    text: 'Content Tools',
    icon: Layout,
    pathname: '/content',
  },
  {
    text: 'Settings',
    icon: Settings,
    pathname: '/settings',
  },
];
export const ActiveRoutes = [PagePaths.Dashboard, PagePaths.Reviews, PagePaths.ItemSales];
export const ActiveContentRoutes = ContentRoutes.filter((route) => ActiveRoutes.includes(route.pathname));
export const ComingSoonContentRoutes = ContentRoutes.filter((route) => !ActiveRoutes.includes(route.pathname));
