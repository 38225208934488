import { DateTime } from 'luxon';

export interface WeekEnding {
  latestWeek: string;
  rolling4Weeks: string[];
  rolling13Weeks: string[];
}

export const getLatestWeek = () => DateTime.now().startOf('week').minus({ days: 8 });

// Extra month just for a buffer
export const get2YearsAgoFromLatestWeek = () => getLatestWeek().minus({ month: 25 });

export const getEndDates = (): WeekEnding => {
  const latestWeek = getLatestWeek();

  const fourWeeksArray: string[] = [];
  fourWeeksArray.push(latestWeek.toFormat('y-MM-dd'));

  for (let weekFromLatest = 1; weekFromLatest < 13; weekFromLatest++) {
    fourWeeksArray.push(latestWeek.minus({ weeks: 4 * weekFromLatest }).toFormat('y-MM-dd'));
  }

  const thirteenWeeksArray: string[] = [];
  thirteenWeeksArray.push(latestWeek.toFormat('y-MM-dd'));

  for (let weekFromLatest = 1; weekFromLatest < 13; weekFromLatest++) {
    thirteenWeeksArray.push(latestWeek.minus({ days: 7 * weekFromLatest }).toFormat('y-MM-dd'));
  }

  return {
    latestWeek: latestWeek.toFormat('y-MM-dd'),
    rolling4Weeks: fourWeeksArray,
    rolling13Weeks: thirteenWeeksArray,
  };
};
